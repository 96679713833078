import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from '../common/PageLoading';
import withRequiredDashboardFilters from '../../hocs/withRequiredDashboardFilters';
import { getDatasetQueryStringParams, getWidgetDatasetIds } from './widgetUtils';
import { setWidgetQueryString, setWidgetLoading, setWidgetReload } from '../../actions/datasetActions';
import { showModal, hideModal } from '../../actions/modalActions';
import { setDrillDownLoading } from '../../actions/commonActions';
import { getAdxQuery, getWidgetData, setCurrentWidget } from '../../actions/widgetActions';
import { getDatasetEndpoint, getLookupEndpoint, getDatasetName } from '../../enums/datasetEndpoint';
import WidgetError from './WidgetError';
import WidgetContainer from './WidgetContainer';
import WidgetRenderer from './WidgetRenderer';
import { getAppliedWidgetFilters, getAppliedFiltersWithDefault, getAppliedWidgetFiltersWithDefault } from '../../selectors/appliedDashboardFilterWithDefaultSelector';
import * as widgetMapper from '../../utils/widgetDataMapper';
import enableCrossFilter  from '../../utils/crossFilterConfig';
import { CultureContext } from '../intl';
import { isTypeCompatible } from './widgetCompatibleTypes';
import { getErrorMessage } from '../../api/apiErrorHandler';
import { setCurrentFilterField, setExcludePageFilterIds } from '../../actions/appliedFiltersActions';
import { addOrUpdateDashboardWidget, setEnableDateIntervalChange } from '../../actions/dashboardActions';
import { mergeWidgetDashboardFilters, getDashboardWidgetFilterIntersection } from '../../utils/widgetFilterUtils';
import { enableDrillDown } from '../../utils/drilldownUtils';
import { userResources } from '../../enums/resources';
import { kqlDatasets } from '../../enums/datasets';
import { useSettingsSelector } from '../../selectors/useSettings';
import { calculatingFiltersSelector } from '../../selectors/calculatingFilters';
import { filterFields, filterIds } from '../../enums/filters';

const widgetFilterField = filterFields.currentWidget;
const dashboardFilterField = filterFields.currentDashboard;

function WidgetManager(props) {
  const {
    id,
    crossFilter,
    wtid,
    widgetTypeId,
    config,
    dataset,
    highchartType,
    userSettings,
    taskStatuses,
    dashboardFiltersWithDefault,
    widgetFiltersWithDefault,
    onHighchartChange,
    requiredDashboardFilterError,
    filterOverrides,
    dashboardLoad,
    reflow,
    dispatch,
    templateName,
    defaultAnalysisAid,
    customTemplateName,
    hasDuplicateWidgetName,
    hasSystemAdminReadPermission,
    widgetFilters,
    permissions,
    widgetDrilldown,
    onCloneItem,
    onRemoveItem,
    onSaveWidgetName,
    buildings,
    isCalculatingFilters,
  } = props;

  const [forceUpdate, setForceUpdate] = useState(Date.now());
  const [queryString, setQueryString] = useState('');
  const [queryParams, setQueryParams] = useState({});
  const [appliedQueryParams, setAppliedQueryParams] = useState({});
  const [computedConfig, setComputedConfig] = useState({});
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [datasetError, setDatasetError] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [datasetResult, setDatasetResult] = useState([]);
  const [adxLookupObject, setAdxLookupObject] = useState({});
  const [widgetFiltersCalculated, setWidgetFitersCalculated] = useState(false);
  const { culture } = useContext(CultureContext);
  const { widgetsLoading, reloadWidgets } = dataset;
  const isWidgetLoading = widgetsLoading[id];
  const shouldReload = reloadWidgets[id];
  const computedHighchartType = computedConfig && computedConfig.chart && computedConfig.chart.type;
  const { current: crossFilterCurrent } = crossFilter;

  const kql = (widgetDrilldown && widgetDrilldown.current)
    ? widgetDrilldown.current.query
    : props.kql;

  const widgetQueryString = (widgetDrilldown && widgetDrilldown.current)
    ? widgetDrilldown.current.query
    : props.queryString;

  const datasetIDs = useMemo(
    () => getWidgetDatasetIds(props.datasetIDs, widgetDrilldown),
    [props.datasetIDs, widgetDrilldown],
  );

  const widgetFilterIntersectionError = useMemo(
    () => getDashboardWidgetFilterIntersection(
      dashboardFiltersWithDefault,
      widgetFiltersWithDefault,
    ),
    [
      dashboardFiltersWithDefault,
      widgetFiltersWithDefault,
    ],
  );
  const mergedFilters = useMemo(
    () => mergeWidgetDashboardFilters(
      dashboardFiltersWithDefault,
      widgetFiltersWithDefault,
      crossFilterCurrent,
      widgetDrilldown,
      filterOverrides,
    ),
    [
      dashboardFiltersWithDefault,
      widgetFiltersWithDefault,
    ],
  );

  useEffect(() => {
    if(crossFilter.enabled && crossFilterCurrent.widgetId !== id) {
      const availableListValues = {
        taskStatuses,
      };
      const { newQueryString, newQueryParams } = getDatasetQueryStringParams({
        kql,
        datasetIDs,
        userSettings,
        mergedFilters,
        widgetQueryString,
        availableListValues,
        filterOverrides,
        crossFilterCurrent,
        buildings,
      });

      setQueryString(newQueryString);
      setQueryParams(newQueryParams);
      setWidgetFitersCalculated(true);
    }
  }, [
    crossFilter,
    mergedFilters,
  ]);

  // calculate api call query
  useEffect(() => {
    if (!crossFilter.enabled && !isCalculatingFilters) {
      const availableListValues = {
        taskStatuses,
      };
      const { newQueryString, newQueryParams } = getDatasetQueryStringParams({
        kql,
        datasetIDs,
        userSettings,
        mergedFilters,
        widgetQueryString,
        availableListValues,
        filterOverrides,
        buildings,
      });
      setQueryString(newQueryString);
      setQueryParams(newQueryParams);
      setWidgetFitersCalculated(true);
    }
  }, [
    mergedFilters,
    taskStatuses,
    isCalculatingFilters,
  ]);

  // drilldown
  useEffect(() => {
    if (widgetDrilldown) {
      const availableListValues = {
        taskStatuses,
      };
      const { newQueryString, newQueryParams } = getDatasetQueryStringParams({
        kql,
        datasetIDs,
        userSettings,
        mergedFilters,
        widgetQueryString,
        availableListValues,
        filterOverrides,
        widgetDrilldown,
        buildings,
      });
      setQueryString(newQueryString);
      setQueryParams(newQueryParams);
      loadDataset({
        willReload: false,
        queryString: newQueryString,
        queryParams: newQueryParams,
      });
    }
  }, [widgetDrilldown]);

  useEffect(() => {
    if (
      (queryString &&
        crossFilter.enabled &&
        crossFilterCurrent.widgetId !== id &&
        !(widgetDrilldown && widgetDrilldown.current)
      ) ||
      (queryString &&
        !crossFilter.enabled &&
        crossFilter.areEqual &&
        crossFilterCurrent.widgetId !== id &&
        !(widgetDrilldown && widgetDrilldown.current)
      )
    ) {
      const availableListValues = {
        taskStatuses,
      };
      const { newQueryString, newQueryParams } = getDatasetQueryStringParams({
        kql,
        datasetIDs,
        userSettings,
        mergedFilters,
        widgetQueryString,
        availableListValues,
        filterOverrides,
        crossFilterCurrent,
        buildings,
      });
      setQueryString(newQueryString);
      setQueryParams(newQueryParams);
      loadDataset({
        willReload: true,
        queryString: newQueryString,
        queryParams: newQueryParams,
      });
    }
  }, [crossFilter, widgetDrilldown]);

  // set widget query string
  useEffect(() => {
    if (queryString) {
      dispatch(setWidgetQueryString({ [id]: queryString }));
    }
  }, [queryString]);

  // load widget dataset on mount
  useEffect(() => {
    if (!loadedOnce && queryString) {
      loadDataset();
      setLoadedOnce(true);
    }
  }, [queryString]);

  // load widget dataset
  useEffect(() => {
    if (dashboardLoad) {
      loadDataset();
    }
  }, [dashboardLoad]);

  // reload one widget
  useEffect(() => {
    if (shouldReload && widgetFiltersCalculated) {
      loadDataset();
      setWidgetFitersCalculated(false);
    }
  }, [shouldReload, widgetFiltersCalculated]);

  // update chartType
  useEffect(() => {
    if (highchartType) {
      setComputedConfig((prevConfig) => prevConfig
        ? overrideHighChartType(prevConfig, highchartType)
        : prevConfig,
      );
    }
  }, [highchartType]);

  const handleShowWidgetFilter = useCallback(() => {
    dispatch(setEnableDateIntervalChange(false));
    dispatch(setCurrentWidget({ id, jsonFilters: widgetFilters }));
    dispatch(setCurrentFilterField(widgetFilterField));
    dispatch(
      showModal(
        'WIDGET_FILTER_MODAL',
        {
          id,
          datasetIDs,
          modalContent: 'half',
          title: 'Widget Filters',
          saveButtonLabel: 'Apply',
          saveCallback: applyFilter,
          cancelCallback: cancelFilter,
        }),
    );
    setTimeout(() => {
      dispatch(setEnableDateIntervalChange(true));
    }, 100);
  }, [id, widgetFilters, datasetIDs]);

  const handleShowCreateTask = useCallback(() => {
    dispatch(
      showModal(
        'CREATE_TASK',
        {
          modalContent: 'full scrollable',
          cancelCallback: cancelFilter,
          data: {
            appliedQueryParams,
            templateName: customTemplateName || templateName,
            crossFilter,
          },
        }),
    );
  }, [appliedQueryParams]);

  function overrideHighChartType(config, type) {
    if (config.chart && config.chart.type && isTypeCompatible(config.chart.type, type)) {
      return {
        ...config,
        chart: { ...config.chart, type },
      };
    }
    return config;
  }

  function handleReloadWidget() {
    applyFilter({ id, widgetFilters });
  }

  function handleHighchartTypeChange(value) {
    onHighchartChange(value);
  }

  function cancelFilter() {
    dispatch(setEnableDateIntervalChange(false));
    dispatch(hideModal());
    dispatch(setCurrentFilterField(dashboardFilterField));
    dispatch(setExcludePageFilterIds([
      filterIds.isBuildingActive,
      filterIds.isEquipmentActive,
      filterIds.isPointActive,
      filterIds.currency,
    ]));
    setTimeout(() => {
      dispatch(setEnableDateIntervalChange(true));
    }, 100);
  }

  function applyFilter(currentWidget) {
    dispatch(setEnableDateIntervalChange(false));
    dispatch(addOrUpdateDashboardWidget(currentWidget));
    dispatch(hideModal());
    dispatch(setCurrentFilterField(dashboardFilterField));
    dispatch(setExcludePageFilterIds([
      filterIds.isBuildingActive,
      filterIds.isEquipmentActive,
      filterIds.isPointActive,
      filterIds.currency,
    ]));
    dispatch(setWidgetReload({ [id]: true  }));
    setWidgetFitersCalculated(false);
    setTimeout(() => {
      dispatch(setEnableDateIntervalChange(true));
    }, 100);
  }

  const downloadAdxQuery =  () => {
    dispatch(getAdxQuery(adxLookupObject))
      .then((data) => {
        const exportData = data[0].query;
        const blob = new Blob([exportData]);
        const URL = window.URL || window.webkitURL;
        const encodedUri = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `adxQuery${wtid}.txt`);

        document.body.appendChild(link);

        link.click();
        URL.revokeObjectURL(encodedUri);
        document.body.removeChild(link);
      });
  };

  function loadDataset(options = { willReload: true }) {
    setDatasetError(false);
    dispatch(setWidgetReload({ [id]: false  }));
    const widgetDataLookupObject = {
      id,
      datasetIDs,
      widgetQueryString,
      queryString: options.queryString || queryString,
      queryParams: options.queryParams || queryParams,
      dataset: getDatasetEndpoint(datasetIDs),
      lookupEndpoint: getLookupEndpoint(datasetIDs),
    };

    if (options.willReload) {
      setHasLoaded(false);
    }
    if (requiredDashboardFilterError) {
      setTimeout(errorHandler, 100);
      return;
    }
    if (widgetFilterIntersectionError) {
      setTimeout(errorHandler, 100);
      return;
    }
    if (options.willReload) {
      dispatch(setWidgetLoading({ [id]: true }));
    }
    if (hasSystemAdminReadPermission && kqlDatasets.includes(datasetIDs[0])) {
      setAdxLookupObject(widgetDataLookupObject);
    }
    dispatch(getWidgetData(widgetDataLookupObject))
      .then((data) => {
        setAppliedQueryParams(widgetDataLookupObject.queryParams);
        if (!data.length) {
          errorHandler('No data found for the filter selections.');
        } else {
          mapDatasetToWidget(data, widgetDataLookupObject.queryParams);
        }
      })
      .catch((err) => {
        let errorMessage = 'Fetching dataset failed.';

        if (err.status === 422 && err.json && err.json.Meta && err.json.Meta.AdditionalDetail) {
          errorMessage = getErrorMessage(err);
        }

        errorHandler(errorMessage);
      }).finally(() => {
        setHasLoaded(true);
        setForceUpdate(Date.now());
        dispatch(setDrillDownLoading({ [id]: false }));
      });
  }

  function errorHandler(errorMessage) {
    setHasLoaded(true);
    setDatasetError(errorMessage);
  }

  function mapDatasetToWidget(response, params) {
    let newConfig = { ...config, datasetIDs, queryParams: params };

    if (newConfig.drilldownMap) {
      newConfig.drilldownMap = newConfig.drilldownMap.map((e, index) => ({ ...e, index }));
    }

    if (widgetDrilldown && widgetDrilldown.current) {
      newConfig = { ...newConfig, dataMapper: widgetDrilldown.current.dataMapper  };
    }

    if (newConfig.dataMapper) {
      if (response[0]) {
        newConfig = enableCrossFilter(newConfig, response[0]);
        newConfig = enableDrillDown(newConfig, widgetDrilldown, highchartType);
      }

      const mappedSeries = newConfig.dataMapper.map((series) => widgetMapper.mapDataToSeries(series, response, newConfig));
      const mappedData = mappedSeries.flat();

      newConfig.series = mappedData;

      const analysisFilter = mergedFilters.find((filter) => filter.key === 'diagnosticAnalysisInterval');
      if (analysisFilter !== undefined && newConfig.analysisInterval && analysisFilter.value !== newConfig.analysisInterval) {
        newConfig.analysisInterval = analysisFilter.value;
      }
      newConfig.culture = culture;
    }

    newConfig = overrideHighChartType(newConfig, highchartType);

    setComputedConfig(newConfig);
    setDatasetResult(response);
  }

  function getDatasetNames() {
    const result = [];
    datasetIDs.forEach((datasetId) => {
      result.push(getDatasetName(datasetId));
    });
    return result.join(', ');
  }

  let error;
  if (requiredDashboardFilterError) {
    error = requiredDashboardFilterError;
  } else if (datasetError) {
    error = <WidgetError widgetError={datasetError} />;
  } else if (widgetFilterIntersectionError) {
    error = <WidgetError widgetError='Applied filters did not return any data' />;
  }

  const forceUpdateConfig = `${computedHighchartType}-${forceUpdate}-${JSON.stringify(crossFilter.current.widgetId)}-${JSON.stringify(crossFilter.current.filters)}`;

  return (
    <React.Fragment>
      {(isWidgetLoading || !hasLoaded) ? (
        <Loader label={'Loading'} boxClass={'widgetLoader'} />
      ) : (
        <WidgetContainer
          hasAdxLookup={adxLookupObject.id ? true : false}
          adxLookupObject={adxLookupObject}
          adxLookup={downloadAdxQuery}
          id={id}
          wtid={wtid}
          templateName={templateName}
          defaultAnalysisAid={defaultAnalysisAid}
          customTemplateName={customTemplateName}
          hasDuplicateWidgetName={hasDuplicateWidgetName}
          hasWidgetFilters={Boolean(widgetFilters.length)}
          hasSystemAdminReadPermission={hasSystemAdminReadPermission}
          hasDashboardUpdatePermission={permissions.dashboard.u}
          hasDashboardReadPermission={permissions.dashboard.r}
          hasWidgetAdminPermission={hasSystemAdminReadPermission}
          reflow={reflow}
          onCloneItem={onCloneItem}
          onRemoveItem={onRemoveItem}
          containerType={error ? 'error' : 'dashboard'}
          onHighchartChange={handleHighchartTypeChange}
          highchartType={computedHighchartType}
          forceUpdateConfig={forceUpdateConfig}
          widgetTypeId={widgetTypeId}
          onShowWidgetFilter={handleShowWidgetFilter}
          onSaveWidgetName={onSaveWidgetName}
          onShowCreateTask={handleShowCreateTask}
          widgetFilters={widgetFilters}
          queryString={queryString}
          datasetName={getDatasetNames()}
          reloadWidget={handleReloadWidget}
        >
          {error || (
            <WidgetRenderer
              id={id}
              wtid={wtid}
              type={widgetTypeId}
              reflow={reflow}
              templateName={templateName}
              config={computedConfig}
              datasetResult={datasetResult}
              forceUpdateConfig={forceUpdateConfig}
              crossFilter={crossFilter}
              datasetIDs={datasetIDs}
            />
          )}
        </WidgetContainer>
      )}
    </React.Fragment>
  );
}

WidgetManager.propTypes = {
  id: PropTypes.string.isRequired,
  wtid: PropTypes.number.isRequired,
  kql: PropTypes.string,
  widgetTypeId: PropTypes.number.isRequired,
  crossFilter: PropTypes.object,
  // crossFilterPrevious: PropTypes.object,
  datasetIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  templateName: PropTypes.string,
  defaultAnalysisAid: PropTypes.number,
  customTemplateName: PropTypes.string,
  config: PropTypes.object,
  onCloneItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  onHighchartChange: PropTypes.func,
  highchartType: PropTypes.string,
  reflow: PropTypes.bool,
  queryString: PropTypes.string,
  dispatch: PropTypes.func,
  dashboardFiltersWithDefault: PropTypes.array,
  taskStatuses: PropTypes.array,
  filterOverrides: PropTypes.string,
  dataset: PropTypes.object,
  dashboardLoad: PropTypes.bool,
  requiredDashboardFilterError: PropTypes.node,
  userSettings: PropTypes.object,
  widgetFilters: PropTypes.array,
  widgetFiltersWithDefault: PropTypes.array,
  onSaveWidgetName: PropTypes.func,
  hasDuplicateWidgetName: PropTypes.bool,
  hasSystemAdminReadPermission: PropTypes.bool,
  permissions: PropTypes.object,
  widgetDrilldown: PropTypes.object,
  buildings: PropTypes.array,
  isCalculatingFilters: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  const widgetFilters = getAppliedWidgetFilters(state, ownProps);
  const dashboardFiltersWithDefault = getAppliedFiltersWithDefault(state, ownProps);
  const widgetFiltersWithDefault = getAppliedWidgetFiltersWithDefault(state, { jsonFilters: widgetFilters });
  const hasSystemAdminReadPermission = state.user.resources.includes(userResources.SystemAdministration);

  return {
    userSettings: useSettingsSelector(state.user),
    crossFilter: state.crossFilter,
    hasSystemAdminReadPermission,
    taskStatuses: state.taskStatuses,
    dataset: state.dataset,
    dashboardLoad: state.ajaxCallsInProgress.dashboardLoad,
    permissions: state.permissions,
    widgetFilters,
    dashboardFiltersWithDefault,
    widgetFiltersWithDefault,
    widgetDrilldown: state.drilldown[ownProps.id],
    buildings: state.buildings,
    isCalculatingFilters: calculatingFiltersSelector(state.calculatingFilters),
  };
}

export default connect(mapStateToProps)(
  withRequiredDashboardFilters(WidgetManager),
);
