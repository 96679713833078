/**
 *  * Created by Stewart Gordon on 8/16/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { process } from '@progress/kendo-data-query';
import { GridCell } from '@progress/kendo-react-grid';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { replace } from 'connected-react-router';
import * as dashboardActions from '../../actions/dashboardActions';
import * as appliedFiltersActions from '../../actions/appliedFiltersActions';
import * as modalActions from '../../actions/modalActions';
import { getUserAvailableTemplates } from '../../selectors/getUserAvailableTemplates';
import IconButton from '../common/IconButton';
import DashboardGrid from './DashboardGrid';
import { filterFields, filterIds } from '../../enums/filters';
import { copyNonOrgSpecificFilters } from '../../actionHelpers/commonHelpers';

/* eslint-disable react/prefer-stateless-function */

const NameCustomColumn = (cellprops) => <td><Link className="link" to={`/${cellprops.dataItem.udid}`}>{cellprops.dataItem.userDashboardName}</Link>{cellprops.dataItem.isDefault ? <span className="icon has-text-success is-big"><i className="flaticon-check" aria-hidden="true" /></span> : null}</td>;
const TypeCustomColumn = (cellprops) => <td><span className="icon custom-icon-container"><i className={cn({'flaticon-hierarchical-structure-1': cellprops.dataItem.type === 3, 'flaticon-globe-1': cellprops.dataItem.type === 2, 'flaticon-user-1': cellprops.dataItem.type === 1 })} aria-hidden="true" /></span></td>;
class Dashboards extends React.Component {
  constructor(props) {
    super(props);

    const dataState = this.createDataState({
      sort: [
        { field: 'userDashboardName', dir: 'asc' },
      ],
      take: 10,
      skip: 0,
    });

    this.state = {
      columns: [
        { title: 'Type', field: 'type', show: true, cell: TypeCustomColumn, filter: 'text', filterable: false, sortable: false, width: 10 },
        { title: 'Name', field: 'userDashboardName', show: true, filter: 'text', cell: NameCustomColumn, width: 40 },
        { title: 'Description', field: 'userDashboardDescription', show: true, filter: 'text', cell: GridCell, width: 50 },
      ],
      ...dataState,
      addNewDashboardTemplateId: '',
    };

    this.onDashboardAddOrCreate = this.onDashboardAddOrCreate.bind(this);
    this.onTemplateSelected = this.onTemplateSelected.bind(this);
    this.onCancelNewDashboard = this.onCancelNewDashboard.bind(this);
  }

  componentDidMount() {
    const { templateId, dashboardTemplates, actions } = this.props;
    document.title = 'Manage Dashboards';
    
    actions.setCurrentFilterField(filterFields.disabled);
    actions.setExcludePageFilterIds([
      filterIds.isBuildingActive,
      filterIds.isEquipmentActive,
      filterIds.isPointActive,
      filterIds.currency,
    ]);

    if (templateId && dashboardTemplates.length === 0) {
      actions.getDashboardTemplates();
    }
  }

  componentDidUpdate(prevProps) {
    const { dashboards, templateId, actions, dashboardTemplates } = this.props;
    if (dashboards !== prevProps.dashboards || dashboardTemplates !== prevProps.dashboardTemplates) {
      const dataState = this.createDataState({
        sort: [
          { field: 'userDashboardName', dir: 'asc' },
        ],
        take: 10,
        skip: 0,
      });
      if ((typeof templateId === 'number') && (dashboards.length > 0)) {
        //Redirect to request dashboard if found
        const dashboardToRedirect = dashboards.find((d) => d.dtid === templateId);
        if (dashboardToRedirect && dashboardToRedirect.udid && typeof dashboardToRedirect.udid === 'number') {
          actions.replace(`/${dashboardToRedirect.udid}`);
          return;
        }
        //get template name and description
        //call onTemplateSelected
        if (dashboardTemplates.length > 0) {
          const globalTemplateToAdd = dashboardTemplates.find((t) => t.dtid === templateId);
          if (globalTemplateToAdd !== undefined) {
            this.onTemplateSelected(`${globalTemplateToAdd.dtid.toString()}g`, globalTemplateToAdd.dashboardTemplateName, globalTemplateToAdd.dashboardTemplateDescription);
          }
        }
      }
      this.setState({ ...dataState });
    }
  }

  // Set Selected Dashboard Template ID (OK Callback for SelectDashboardTemplate)
  onTemplateSelected(id, name, description) {
    const { actions } = this.props;
    this.setState({
      addNewDashboardTemplateId: id,
    });
    actions.showModal('ADD_TEMPLATE_OR_DASHBOARD', {
      modalContent: 'full',
      cancelButtonLabel: 'Cancel',
      cancelCallback: this.onCancelNewDashboard,
      okButtonLabel: 'Add',
      okCallback: this.onDashboardAddOrCreate,
      closeModal: actions.hideModal,
      title: 'Add Dashboard',
      templateName: name,
      templateDescription: description,
    });
  }

  // Reset addNewDashboardTemplateId id to '' and hideModal (Cancel Callback for either Add Dashboard OR Create Dashboard)
  onCancelNewDashboard() {
    const { actions } = this.props;
    this.setState({ addNewDashboardTemplateId: '' });
    actions.hideModal();
  }

  onDashboardAddOrCreate(name, description) {
    const { dashboardTemplates, actions } = this.props;
    const { addNewDashboardTemplateId } = this.state;

    const dashboardData = {
      userDashboardName: name,
      userDashboardDescription: description,
      isDefault: false,
      jsonLayout: JSON.stringify({ lg: [], md: [], sm: [] }),
      jsonFilters: JSON.stringify([]),
      jsonWidgetSettings: JSON.stringify({ widgets: [] }),
    };

    let templateToAdd;
    let isOrgTemplate = false;
    const lastChar = addNewDashboardTemplateId.slice(-1);

    if (addNewDashboardTemplateId) {
      const templateId = addNewDashboardTemplateId.slice(0,-1);
      if (lastChar === 'g') {
        dashboardData.dtid = templateId;
        templateToAdd = dashboardTemplates.find((t) => t.dtid === parseInt(templateId, 10));
      } else {
        isOrgTemplate = true;
        dashboardData.udtid = templateId;
        templateToAdd = dashboardTemplates.find((t) => t.udtid === parseInt(templateId, 10));
      }
    }

    if (templateToAdd) {
      const jsonFilters = isOrgTemplate
        ? templateToAdd.jsonFilters
        : copyNonOrgSpecificFilters(templateToAdd.jsonFilters);

      dashboardData.jsonFilters = JSON.stringify(jsonFilters);

      templateToAdd.jsonWidgetSettings = typeof templateToAdd.jsonWidgetSettings === 'string'
        ? JSON.parse(templateToAdd.jsonWidgetSettings)
        : templateToAdd.jsonWidgetSettings;
      const jsonWidgetSettings = {
        ...templateToAdd.jsonWidgetSettings,
        widgets: templateToAdd.jsonWidgetSettings.widgets.map((widget) => ({
          ...widget,
          jsonFilters: isOrgTemplate
            ? widget.jsonFilters
            : copyNonOrgSpecificFilters(widget.jsonFilters),
        })),
      };
      dashboardData.jsonWidgetSettings = JSON.stringify(jsonWidgetSettings);
    }

    // check for success or failure
    return actions.createDashboard(dashboardData);
    // redirect to the new Dashboard
  }

  onColumnsSubmit = (columnsState) => {
    this.setState({
      columns: columnsState,
    });
  };

  createDataState(dataState) {
    return {
      result: process(this.props.dashboards.slice(0), dataState),
      dataState,
    };
  }

  dataStateChange = (event) => {
    this.setState(this.createDataState(event.data));
  };


  render() {
    const { actions, permissions } = this.props;
    const { columns, dataState, result } = this.state;
    const hasDashboardCreatePermission = permissions.dashboard.c;


    return (
      <div className="column content-spacing-container">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h1 className="title">Manage Dashboards</h1>
            </div>
          </div>
          {hasDashboardCreatePermission && (
            <div className="level-right">
              <div className="level-item">
                <IconButton
                  iconName="flaticon-plus"
                  onClick={() => actions.showModal('SELECT_DASHBOARD_TEMPLATE', {
                    modalContent: 'full',
                    cancelButtonLabel: 'Cancel',
                    cancelCallback: this.onCancelNewDashboard,
                    okButtonLabel: 'Next',
                    okCallback: this.onTemplateSelected,
                    title: 'Add Dashboard',
                  },
                  )}
                  title="Add Dashboard"
                  text="Add Dashboard"
                  style={{ marginRight: '10px' }}
                  className="dashboard-icon-button"
                />
                <IconButton
                  iconName="flaticon-plus"
                  onClick={() => actions.showModal('ADD_TEMPLATE_OR_DASHBOARD', {
                    modalContent: 'full',
                    cancelButtonLabel: 'Cancel',
                    cancelCallback: this.onCancelNewDashboard,
                    okButtonLabel: 'Create',
                    okCallback: this.onDashboardAddOrCreate,
                    closeModal: actions.hideModal,
                    title: 'Create Dashboard',
                  })}
                  title="Create Dashboard"
                  text="Create Dashboard"
                  className="dashboard-icon-button"
                />
              </div>
            </div>
          )}
        </div>

        <h6 className="subtitle is-6" style={{ marginBottom: '2px' }}> <span className="icon has-text-success is-small"><i className="flaticon-check-1" aria-hidden="true" /></span> = Homepage,&nbsp;
          <span className="icon is-small"><i className="flaticon-globe-1" aria-hidden="true" /></span> = global,&nbsp;
          <span className="icon is-small"> <i className="flaticon-hierarchical-structure-1" aria-hidden="true" /></span> = organization,&nbsp;
          <span className="icon is-small"> <i className="flaticon-user-1" aria-hidden="true" /></span> = user</h6>
        <DashboardGrid
          result={result}
          dataState={dataState}
          columns={columns}
          onDataStateChange={this.dataStateChange}
          onColumnsSubmit={this.onColumnsSubmit}
        />
      </div>
    );
  }
}

Dashboards.propTypes = {
  actions: PropTypes.object.isRequired,
  dashboards: PropTypes.array.isRequired,
  dashboardTemplates: PropTypes.array.isRequired,
  permissions: PropTypes.object,
  templateId: PropTypes.number,
  userAvailableTemplates: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    templateId: parseInt(ownProps.match.params.templateId, 10),
    dashboards: state.dashboards,
    dashboardTemplates: state.dashboardTemplates,
    permissions: state.permissions,
    userAvailableTemplates: getUserAvailableTemplates(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, modalActions, dashboardActions, appliedFiltersActions, { replace }), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboards);

