import * as types from './actionTypes';
import * as api from '../api/api';
import { editEquipmentFlagsLoading } from './ajaxStatusActions';
import { apiErrorHandler } from '../api/apiErrorHandler';
import handleErrorResponses from './errorActions';

export function EditEquipmentSuccess(payload) {
  return { type: types.EDIT_EQUIPMENT_FLAGS_SUCCESS, payload };
}

export const editEquipmentFlags = (payload) => (dispatch) => {
  dispatch(editEquipmentFlagsLoading());
  return api.editEquipmentFlags(payload)
    .then((response) => dispatch(EditEquipmentSuccess(response)))
    .catch((error) => { handleErrorResponses(error); throw (error); });
};

// Get Equipment permissions by Unit ID
function getEquipmentPermissionByUnitIdSuccess(payload) {
  return { type: types.GET_EQUIPMENT_PERMISSION_BY_UNIT_ID_SUCCESS, payload };
}
function getEquipmentPermissionByUnitIdError(error) {
  return { type: types.GET_EQUIPMENT_PERMISSION_BY_UNIT_ID_ERROR, error };
}
export function getEquipmentPermissionByUnitId(unitId) {
  return (dispatch) => api.getEquipmentPermission(unitId)
    .then((response) => {
      dispatch(getEquipmentPermissionByUnitIdSuccess({ unitId, permissions: response }));
      return response;
    }).catch((error) => {
      dispatch(getEquipmentPermissionByUnitIdError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Bulk Edit Equipment
export function bulkEditEquipmentDetailsSaving(payload) {
  return { type: types.BULK_EDIT_EQUIPMENT_DETAILS_SAVING, payload };
}

export function bulkEditEquipmentDetailsSuccess(payload) {
  return { type: types.BULK_EDIT_EQUIPMENT_DETAILS_SUCCESS, payload };
}

export function bulkEditEquipmentDetails(data) {
  return (dispatch) => {
    const payload = {
      'EID': data.eid,
      'resource': {
        'update': {
          'Active': data.active,
          'Visible': data.visible,
          'EquipmentTypeID': data.equipmentTypeId,
          'ConfigurationStatusID': data.configurationStatusId,
        },
      },
    };
    dispatch(bulkEditEquipmentDetailsSaving(true));
    return api.bulkEditEquipmentDetails(payload)
      .then((data) => {
        dispatch(bulkEditEquipmentDetailsSuccess(data.data));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error, { showDialog: true, addModal: true }));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(bulkEditEquipmentDetailsSaving(false));
      });
  };
}
