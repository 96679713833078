import React, { useState } from 'react';
import PropTypes from 'prop-types';

const TopFilter = (props) => {
  const [topValue, setTopValue] = useState(props.topValue);
  const [topBy, setTopBy] = useState(props.topBy);

  const propagateChange = (newValue) => {
    if (newValue.topValue !== props.topValue || newValue.topBy !== props.topBy) {
      props.onChange(newValue);
    }
  };

  const handleChangeTopValue = (event) => {
    const newValue = event.target.value;
    setTopValue(newValue);
  };

  const handleChangeTopBy = (event) => {
    const newValue = event.target.value;
    setTopBy(newValue);
    propagateChange({
      topValue,
      topBy: newValue,
    });
  };

  const handleKeyDown = (e) => {
    if ((e.keyCode || e.which) === 13) {
      propagateChange({
        topValue,
        topBy,
      });
    }
  };

  const handleBlur = () => {
    propagateChange({
      topValue,
      topBy,
    });
  };

  return (
    <section className="diagnostics-top-results-filter card">
      <div className="card-content is-flex">
        <span className="icon">
          <i className="flaticon-sort" aria-hidden="true" />
        </span>
  
        <div className='is-flex topValue'>
          <label htmlFor="topValue">
            Viewing top
          </label>
          <input
            id="topValue"
            type="text"
            className="input is-small"
            disabled={props.loading}
            value={topValue}
            onChange={handleChangeTopValue}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        </div>
  
        <div className='is-flex topBy'>
          <label htmlFor="topBy">
            records by
          </label>
          <div className="select is-small">
            <select
              name="topBy"
              disabled={props.loading}
              value={topBy}
              onChange={handleChangeTopBy}
            >
              {options.map((opt) => <option key={opt.value} value={opt.value}>
                {opt.text}
              </option>)}
            </select>
          </div>
        </div>
      </div>
    </section>
  );
};

const options = [
  {
    value: 'avoidableCost',
    text: 'Avoidable Cost',
  },
  {
    value: 'energyPriority',
    text: 'Energy Priority',
  },
  {
    value: 'comfortPriority',
    text: 'Comfort Priority',
  },
  {
    value: 'maintenancePriority',
    text: 'Maintenance Priority',
  },
];

export const topFilterFieldMapping = {
  avoidableCost: 'summedAvoidableCost',
  energyPriority: 'averagedEnergyPriority',
  comfortPriority: 'averagedComfortPriority',
  maintenancePriority: 'averagedMaintenancePriority',
};

export const topFilterDefaultValues = {
  topValue: 100,
  topBy: options[0].value,
};

TopFilter.propTypes = {
  topBy: PropTypes.string.isRequired,
  topValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
};

export default TopFilter;
