import Highcharts from 'highcharts';
import { widgetDatasets } from '../../../../enums/datasets';
import widgetType from '../../../widgets/widgetType';

export const dataPointsConfigSettings = {
  'id': 'dataPointsConfigSettings',
  'kql': `
    PointsDataset
    | distinct PointID
    | summarize count()
  `,
  'filterOverrides': '',
  'datasetIDs': [widgetDatasets.Points],
  'type': widgetType.chart,
  'series': [],
  'dataMapper': [],
};

export const dataPointsConfigSettings2 = {
  'id': 'dataPointsConfigSettings2',
  'kql': `
    AggregatedRawDataDataset
    | summarize CountSum = sum(Count) by StartDateLoggedLocal
    | order by StartDateLoggedLocal desc
  `,
  'filterOverrides': 'StartDate=$d30&AggregationInterval=Daily',
  'datasetIDs': [widgetDatasets.AggregatedRawDataDataset],
  'type': widgetType.chart,
  'config': {
    'chart': {
      'type': 'areaspline',
    },
    'plotOptions': {
      'series': {
        'turboThreshold': 0,
      },
      'areaspline': {
        'lineWidth': 3,
        'color': '#144597',
        'fillColor': {
          'linearGradient' : {
            'x1': 0,
            'x2': 0,
            'y1': 0,
            'y2': 1,
          },
          'stops' : [
            [0, '#144597'],
            [1, Highcharts.Color('#99B8F7').setOpacity(0.2).get('rgba')],
          ],
        },
      },
    },
    'credits': 'false',
    'exporting': {
      'enabled': false,
    },
    'boost': {
      'enabled': false,
    },
    'title': {
      'text': null,
    },
    'legend': {
      'enabled': false,
    },
    'tooltip': {
      'shared': true,
    },
    'xAxis': {
      'type': 'datetime',
      'gridLineWidth': 0,
      'tickWidth': 0,
    },
    'yAxis': {
      'title': {
        'enabled': false,
      },
      'gridLineWidth': 0,
      'tickWidth': 0,
    },
    'series': [],
    'sampleData': [],
    'dataMapper': [
      {
        'name': {
          'type': 'string',
          'value': 'Point Count',
        },
        'data': {
          'x': {
            'type': 'date',
            'value': 'startDateLoggedLocal',
          },
          'y': {
            'type': 'field',
            'value': 'countSum',
          },
        },
      },
    ],
    'kgs': [
      {
        'name': 'xAxisByInterval',
        'args': [
          'M/d',
          'M/d',
          'M/d',
        ],
        'analysisInterval': 'Daily',
      },
    ],
  },
  'crossFilter': {
    'enabled': false,
    'areEqual': false,
    'current': {
      'widgetId': null,
      'filters': [],
    },
    'previous': {
      'widgetId': null,
      'filters': [],
    },
  },
};

export const dataPointsConfigSettings3 = {
  'id': 'dataPointsConfigSettings3',
  'kql': `
    AggregatedRawDataDataset
    | where isnull(Count)
    | distinct PID
  `,
  'filterOverrides': 'StartDate=$d0&AggregationInterval=Daily',
  'datasetIDs': [widgetDatasets.AggregatedRawDataDataset],
  'type': widgetType.chart,
  'series': [],
  'dataMapper': [],
};
