import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setCurrentFilterField, setCurrentFilterFieldSkipFilters, setExcludePageFilterIds } from '../actions/appliedFiltersActions';
import { filterFields, filterIds } from '../enums/filters';
import { buildingModuleJsonFilterSelector } from '../selectors/buildingModuleJsonFilterSelector';
import { hasFilterValue } from '../actionHelpers/commonHelpers';
import { setGlobalJsonFilters } from '../actions/userActions';
import { addOrUpdateJsonFilters, setGroupFilters } from '../actions/commonActions';
import FilterContext from '../components/filters/FilterContext';
import PageLoading from '../components/common/PageLoading';

const withBuildingsModuleWrapper = (WrappedComponent) => (props) => {
  const { setPageFiltersHasChanged } = useContext(FilterContext);

  const dispatch = useDispatch();
  const location = useLocation();
  const search = location.search;
  const prevSearch = useRef(search);
  const [mounted, setMounted] = useState(false);

  const jsonFilters = useSelector((state) => state.buildingModule.config.jsonFilters);
  const jsonFiltersFromAPILoaded = useSelector((state) => state.buildingModule.config.jsonFiltersFromAPILoaded);
  const jsonFiltersFromQueryString = useSelector(buildingModuleJsonFilterSelector);
  const buildingModuleJsonFilters = mounted ? jsonFilters : jsonFiltersFromQueryString || jsonFilters;

  useEffect(() => {
    document.title = 'Assets | Buildings';
    dispatch(setExcludePageFilterIds([
      filterIds.equipmentClass,
      filterIds.equipmentType,
      filterIds.equipment,
      filterIds.isEquipmentVisible,
      filterIds.isEquipmentActive,
      filterIds.configurationStatusId,

      filterIds.diagnosticDate,     
      filterIds.diagnosticAnalysisInterval,     
      filterIds.analysis,     
      filterIds.diagnosticDate,     
      filterIds.diagnosticDate,     
      filterIds.diagnosticDate,     
      filterIds.energy,
      filterIds.comfort,
      filterIds.maintenance,
      filterIds.avoidableCost,
      
      filterIds.taskOpenedDate,
      filterIds.taskCompletionDate,
      filterIds.taskModifiedDate,
      filterIds.taskStatus,
      filterIds.taskAssignee,
      filterIds.taskReporter,
      filterIds.annualAvoidableCost,

      filterIds.pointClass,
      filterIds.pointType,
      filterIds.point,
      filterIds.vPoint,
      filterIds.isPointActive,
      filterIds.rawdatasetDate,
      filterIds.dataSource,

      filterIds.currency,
    ]));
  }, []);

  const setFilters = async () => {
    if (jsonFiltersFromQueryString) {
      const providerFilter = buildingModuleJsonFilters.find((e) => e.key === 'provider');
      const clientFilter = buildingModuleJsonFilters.find((e) => e.key === 'client');

      if (hasFilterValue(providerFilter) || hasFilterValue(clientFilter)) {
        const newGlobalFilters = [providerFilter, clientFilter].filter(Boolean);
        dispatch(setGlobalJsonFilters(newGlobalFilters, true));
      }

      dispatch(setCurrentFilterFieldSkipFilters(filterFields.buildingModule));
      dispatch(addOrUpdateJsonFilters(buildingModuleJsonFilters, true));
      await dispatch(setGroupFilters({ skipGlobalFilters: false }));
      setPageFiltersHasChanged(true);
      setMounted(true);
    }
  };

  const setModule = async () => {
    await dispatch(setCurrentFilterField(filterFields.buildingModule));
    setMounted(true);
  };

  useEffect(() => {
    if (jsonFiltersFromAPILoaded && !jsonFiltersFromQueryString) {
      setModule();
    }
  }, [jsonFiltersFromAPILoaded, jsonFiltersFromQueryString]);

  useEffect(() => {
    const prev = prevSearch.current;
    const current = search;
    if (prev && !current) {
      history.push({
        pathname: location.pathname,
        state: { forceRefresh: Date.now() },
      });
    }
    prevSearch.current = search;
  }, [search]);

  useEffect(() => {
    if (!jsonFiltersFromAPILoaded) return;
    setFilters();
  }, [search, jsonFiltersFromAPILoaded]);

  if (!mounted) {
    return <PageLoading label="Loading" boxClass={'page-loading-container'} />;
  }

  return mounted && (
    <WrappedComponent
      {...props}
      key={search}
      search={search}
      buildingModuleJsonFilters={buildingModuleJsonFilters}
    />
  );
};

export default withBuildingsModuleWrapper;
