import React from 'react';
import PropTypes from 'prop-types';

function CheckboxListItem(props) {
  const { label, id, checked, onChange } = props;

  return (
    <div
      className="searchableMultiselect-checkboxItem"
      title={label}
      onClick={handleChange}
    >
      <div className="checkbox">
        <input
          id={id}
          type="checkbox"
          checked={checked}
          onChange={handleChange}
        />
        {label}
      </div>
    </div>
  );

  function handleChange(evt) {
    const options = {
      id,
      checked,
    };
    onChange(evt, options);
  }
}

CheckboxListItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckboxListItem;
