import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formatDate } from '@telerik/kendo-intl';
import { utcStringToDatePlusOffset, scrollToFormField, mapper } from '../../utils';
import { apiErrorHandler } from '../../api/apiErrorHandler';
import * as modalActions from '../../actions/modalActions';
import * as taskActions from '../../actions/taskModuleActions';
import * as buildingSettingsActions from '../../actions/buildingSettingsActions';
import { replace } from 'connected-react-router';
import * as widgetActions from '../../actions/widgetActions';
import TextArea from '../common/TextArea';
import NumberInput from '../common/NumberInput';
import DatePickerInput from '../common/DatePickerInput';
import SelectInput from '../common/SelectInput';
import CheckboxInput from '../common/CheckboxInput';
import { formatDateTime } from '../tasks/taskModal/utils';
import LabelValue from '../common/LabelValue';
import { toastr } from 'react-redux-toastr';
import { getDateInterval } from '../../actionHelpers/diagnosticHelpers';
import { intervalOptions, isStatusCompleted } from '../tasks/utils';
import { editTaskModalSelector } from '../../selectors/editTaskModal';
import toMap from '../../utils/toMap';

const EditTaskDetailsModal = ({
  details, detailsSaving, culture, saveCallback, cancelCallback, actions, assigneesAndReporters, assigneesAndReportersLoading,
  taskStatuses, analysis, equipmentAnalysisAll, equipmentAll: allEquipments, buildingSettings, 
}) => {
  const editModalRef = useRef(null);
  const today = useRef(new Date());

  const [taskDetails] = useState({ ...details });

  const initialValues = {
    reporterUid: taskDetails.reporterUid === null ? '' : taskDetails.reporterUid.toString(),
    assignedUid: taskDetails.assignedUid === null ? '' : taskDetails.assignedUid.toString(),
    summary: taskDetails.summary,
    statusId: taskDetails.statusId.toString(),
    dateCompleted: taskDetails.dateCompleted ? utcStringToDatePlusOffset(taskDetails.dateCompleted) : null,
    description: taskDetails.description === null ? '' : taskDetails.description,
    recommendations: taskDetails.recommendations === null ? '' : taskDetails.recommendations,
    actions: taskDetails.actions === null ? '' : taskDetails.actions,
    analysisId: taskDetails.analysisId,
    diagnosticDate: taskDetails.diagnosticDate !== null ? utcStringToDatePlusOffset(taskDetails.diagnosticDate) : null,
    analysisInterval: taskDetails.analysisInterval,
    annualAvoidableCost: taskDetails.annualAvoidableCost,
    annualAvoidableCoolingUse: taskDetails.annualAvoidableCoolingUse,
    annualAvoidableElectricUse: taskDetails.annualAvoidableElectricUse,
    annualAvoidableHeatingUse: taskDetails.annualAvoidableHeatingUse,
    annualAvoidableCarbon: taskDetails.annualAvoidableCarbon,
    additionalSavings: taskDetails.additionalSavings,
    hasGenerateWorkOrder: taskDetails.hasGenerateWorkOrder,
  };

  const [errors, setErrors] = useState('');
  const [hasNotification, setHasNotification] = useState(false);
  const [recalculateSavings, setRecalculateSavings] = useState(false);
  const [hasGenerateWorkOrder, setHasGenerateWorkOrder] = useState(initialValues.hasGenerateWorkOrder);
  const [assigned, setAssigned] = useState(initialValues.assignedUid);

  const [dateCompleted, setDateCompleted] = useState(initialValues.dateCompleted);
  const [dateCompletedErrors, setDateCompletedErrors] = useState([]);
  const [reporter, setReporter] = useState(initialValues.reporterUid);
  const [statusId, setStatusId] = useState(initialValues.statusId);
  const [summary, setSummary] = useState(initialValues.summary);
  const [summaryError, setSummaryError] = useState(['']);
  const [description, setDescription] = useState(initialValues.description);
  const [recommendations, setRecommendations] = useState(initialValues.recommendations);
  const [actionsText, setActionsText] = useState(initialValues.actions);
  const [annualAvoidableCost, setAnnualAvoidableCost] = useState(initialValues.annualAvoidableCost);
  const [additionalSavings, setAdditionalSavings] = useState(initialValues.additionalSavings);
  const [annualAvoidableCoolingUse, setAnnualAvoidableCoolingUse] = useState(initialValues.annualAvoidableCoolingUse);
  const [annualAvoidableElectricUse, setAnnualAvoidableElectricUse] = useState(initialValues.annualAvoidableElectricUse);
  const [annualAvoidableHeatingUse, setAnnualAvoidableHeatingUse] = useState(initialValues.annualAvoidableHeatingUse);
  const [annualAvoidableCarbon, setAnnualAvoidableCarbon] = useState(initialValues.annualAvoidableCarbon);
  const [assigneeOptions, setAssigneeOptions] = useState(taskDetails.assignedUid === null ? [] : [{ value: taskDetails.assignedUid.toString(), text: taskDetails.assignee }]);
  const [reporterOptions, setReporterOptions] = useState(taskDetails.reporterUid === null ? [] : [{ value: taskDetails.reporterUid.toString(), text: taskDetails.reporter }]);
  const [statusOptions] = useState(taskStatuses.map((e) => ({ text: e.statusName, value: e.statusId })));
  const [analysisId, setAnalysisId] = useState(initialValues.analysisId);
  const [diagnosticDate, setDiagnosticDate] = useState(initialValues.diagnosticDate);
  const [analysisInterval, setAnalysisInterval] = useState(initialValues.analysisInterval);

  const isEnableCreateWorkOrder = useMemo(
    () => buildingSettings.length > 0 && buildingSettings.every((setting) => setting.isEnableCreateWorkOrder),
    [buildingSettings],
  );

  const hasCMMSReferenceId = useMemo(
    () => {
      const equipment = allEquipments.find((item) => item.id === taskDetails.equipmentId);
      return equipment && !!equipment.cmmsReferenceId;
    },
    [allEquipments, taskDetails],
  );

  const canCreateWorkOrder = isEnableCreateWorkOrder && hasCMMSReferenceId;
  const isCompleted = isStatusCompleted(statusId);

  useEffect(() => {
    if (assigneesAndReporters) {
      let assignees = assigneesAndReporters.map((currentAssignee) => ({ value: currentAssignee.uid.toString(), text: currentAssignee.email }));
      let reporters = assigneesAndReporters.map((currentReporter) => ({ value: currentReporter.uid.toString(), text: currentReporter.email }));
      if (taskDetails.assignedUid !== null && (assignees.findIndex((currentAssignee) => taskDetails.assignedUid.toString() === currentAssignee.value) === -1)) {
        assignees.unshift({ value: taskDetails.assignedUid, text: taskDetails.assignee });
      }
      if (taskDetails.reporterUid !== null && (reporters.findIndex((currentReporter) => taskDetails.reporterUid.toString() === currentReporter.value) === -1)) {
        reporters.unshift({ value: taskDetails.reporterUid, text: taskDetails.reporter });
      }
      setReporterOptions(reporters);
      setAssigneeOptions(assignees);
    }
  }, [assigneesAndReporters]);

  useEffect(() => {
    if (taskDetails.buildingId) {
      actions.getBuildingSettings([taskDetails.buildingId]);
    } else {
      actions.clearBuildingSettings();
    }
    return () => {
      actions.clearBuildingSettings();
    };
  }, [taskDetails.buildingId]);

  const equipmentAnalysisAllToMap = useMemo(
    () => toMap(equipmentAnalysisAll, 'eid'),
    [equipmentAnalysisAll, taskDetails.equipmentId],
  );

  const equipmentAnalysis = useMemo(
    () => {
      const found = equipmentAnalysisAllToMap.get(taskDetails.equipmentId);
      if (!found) return [];
      return analysis.filter((a) => found.aids.includes(a.aid));
    },
    [equipmentAnalysisAllToMap, analysis, taskDetails.equipmentId],
  );

  const analysisOptions = useMemo(
    () => mapper(
      equipmentAnalysis, {
        inputValueKey: 'aid',
        inputLabelKey: 'analysisName',
        outputValueKey: 'value',
        outputLabelKey: 'text',
      },
    ),
    [equipmentAnalysis],
  );

  const handleChangeInput = (setter) => (event) => {
    const { name, value, checked } = event.target;
    const newValue = typeof checked === 'boolean' ? checked : value;
    setter(newValue);
    if (newValue && name === 'diagnosticDate') {
      setAnalysisInterval(getDateInterval(newValue));
    }
    if (name === 'statusId' && isStatusCompleted(newValue)) {
      const newDateCompleted =
      dateCompleted
        ? dateCompleted
        : taskDetails.dateCompleted
          ? utcStringToDatePlusOffset(taskDetails.dateCompleted)
          : new Date();
      setDateCompleted(newDateCompleted);
    }
  };

  const getUpdatedValues = (initial, updated) => {
    const values = {};
    Object.keys(initial).forEach((key) => {
      if (
        (initial[key] === updated[key]) ||
        (initial[key] === null && isNaN(updated[key])) ||
        (typeof updated[key] === 'number' && String(updated[key]) === initial[key])
      ) return;
      if (
        initial[key] instanceof Date && updated[key] instanceof Date &&
        initial[key].toISOString() === updated[key].toISOString()
      ) return;
      if (
        key === 'diagnosticDate' &&
        formatDate(initial[key], 'yyyy-MM-dd') === updated[key]
      ) return;
      values[key] = updated[key];
    });
    return values;
  };

  const saveEdit = (event) => {
    event.preventDefault();
    setErrors('');
    if(formIsValid()) {
      const statusIdInt = parseInt(statusId);
      const updatedValues = {
        reporterUid: parseInt(reporter, 10),
        assignedUid: parseInt(assigned, 10),
        summary,
        statusId: statusIdInt,
        dateCompleted: isCompleted ? dateCompleted : undefined,
        description,
        recommendations,
        actions: actionsText,
        analysisId: parseInt(analysisId, 10),
        diagnosticDate: formatDate(diagnosticDate, 'yyyy-MM-dd'),
        analysisInterval,
        hasGenerateWorkOrder,
        additionalSavings: parseInt(additionalSavings, 10),
        annualAvoidableCarbon: parseInt(annualAvoidableCarbon, 10),
        annualAvoidableCost: parseInt(annualAvoidableCost, 10),
        annualAvoidableCoolingUse: parseInt(annualAvoidableCoolingUse, 10),
        annualAvoidableElectricUse: parseInt(annualAvoidableElectricUse, 10),
        annualAvoidableHeatingUse: parseInt(annualAvoidableHeatingUse, 10),
      };
      const payload = getUpdatedValues(initialValues, updatedValues);
      actions.editTaskDetails(
        taskDetails.buildingId,
        taskDetails.taskId,
        {
          meta: {
            hasNotification,
            recalculateSavings,
          },
          resource: {
            taskHyperlink: taskDetails.taskHyperlink,
            diagnosticHyperlink: taskDetails.diagnosticHyperlink,
            update: payload,
          },
        },
      )
        .then(() => {
          const toastText = 'Task Saved.';
          toastr.success(toastText, toastText, {
            removeOnHover: true,
            removeOnHoverTimeOut: 1000,
          });
          if (saveCallback) {
            saveCallback();
          } else {
            cancelCallback();
          }
        }).catch(async (err) => {
          const message = await actions.apiErrorHandler(err, { showDialog: false });
          setErrors(message);
        });
    }
  };

  const formIsValid = () => {
    let errors = [];
    if (summary && summary !== '') {
      setSummaryError(['']);
    } else {
      setSummaryError(['Summary is required!']);
      errors = ['summary'];
    }
    if (isCompleted && !dateCompleted) {
      setDateCompletedErrors(['Date Completed is required!']);
      errors.push('dateCompleted');
    } else {
      setDateCompletedErrors([]);
    }
    if (errors.length) {
      //scroll to the error
      scrollToFormField(editModalRef,errors[0]);
      return false;
    }
    return true;
  };

  return (
    <form onSubmit={(evt) => evt.preventDefault()} className={'box'} name={'editModal'} ref={editModalRef}  >
      <div className="panel-block is-wrapped filter no-border modal-header" style={{ paddingLeft: '0', paddingRight: '0'}}>
        <h1 className="title" style={{ marginBottom: '24px' }}>Edit Task</h1>
      </div>

      {errors && <div>
        <h6 style={{ marginBottom: '10px' }} className="is-6 subtitle has-text-danger">{errors}</h6>
      </div>}

      <div className="box modal-main" style={{ minHeight: 120 }}>
        <TextArea
          label={'*Summary:'}
          name={'summary'}
          maxLength={250}
          errors={summaryError}
          value={summary}
          onChange={handleChangeInput(setSummary)}
        />

        <LabelValue label="Building Name:" value={taskDetails.buildingName} />
        <LabelValue label="Equipment Name:" value={taskDetails.equipmentName} />

        <SelectInput
          label={'*Analysis Name:'}
          isLoading={false}
          options={analysisOptions}
          name={'analysisId'}
          value={analysisId}
          onChange={handleChangeInput(setAnalysisId)}
        />

        <DatePickerInput
          label={'*Task Diagnostic Date:'}
          name={'diagnosticDate'}
          value={diagnosticDate}
          max={today.current}
          onChange={handleChangeInput(setDiagnosticDate)}
        />

        <SelectInput
          label={'*Interval:'}
          isLoading={false}
          options={intervalOptions}
          name={'analysisInterval'}
          value={analysisInterval}
          onChange={handleChangeInput(setAnalysisInterval)}
        />

        <SelectInput
          label={'Reporter:'}
          name={'reporter'}
          isLoading={assigneesAndReportersLoading}
          errors={['']}
          info={reporter === (taskDetails.reporterUid === null ? '' : taskDetails.reporterUid.toString()) ? [details.reporterDetail] : ['']}
          defaultOption={'No Reporter'}
          options={reporterOptions}
          value={reporter}
          onChange={handleChangeInput(setReporter)}
        />

        <SelectInput
          label={'Assignee:'}
          name={'assigned'}
          isLoading={assigneesAndReportersLoading}
          errors={['']}
          info={assigned === (taskDetails.assignedUid === null ? '' : taskDetails.assignedUid.toString()) ? [details.assigneeDetail] : ['']}
          defaultOption={'No Assignee'}
          options={assigneeOptions}
          value={assigned}
          onChange={handleChangeInput(setAssigned)}
        />

        <SelectInput
          label={'*Status:'}
          name={'statusId'}
          isLoading={false}
          errors={['']}
          info={['']}
          options={statusOptions}
          value={statusId}
          onChange={handleChangeInput(setStatusId)}
        />

        <DatePickerInput
          min={utcStringToDatePlusOffset(taskDetails.dateCreated)}
          label={`${isCompleted ? '*' : ''}Date Completed:`}
          name={'dateCompleted'}
          errors={dateCompletedErrors}
          disabled={!isCompleted}
          value={isCompleted ? dateCompleted : null}
          onChange={handleChangeInput(setDateCompleted)}
        />

        <TextArea
          label={'Description:'}
          maxLength={5000}
          name={'description'}
          value={description}
          onChange={handleChangeInput(setDescription)}
        />

        <TextArea
          label={'Recommendations:'}
          maxLength={5000}
          name={'recommendations'}
          value={recommendations}
          onChange={handleChangeInput(setRecommendations)}
        />

        <TextArea
          label={'Actions:'}
          maxLength={5000}
          name={'actionsText'}
          value={actionsText}
          onChange={handleChangeInput(setActionsText)}
        />

        <CheckboxInput
          label={'Email?'}
          name={'hasNotification'}
          checked={hasNotification}
          onChange={handleChangeInput(setHasNotification)}
        />

        <CheckboxInput
          label={'Recalculate savings'}
          name={'recalculateSavings'}
          checked={recalculateSavings}
          onChange={handleChangeInput(setRecalculateSavings)}
        />

        <NumberInput
          errors={['']}
          label={'Annual Avoidable Cost:'}
          format={'n'}
          unitLabel={taskDetails.isoCurrencySymbol}
          name={'annualAvoidableCost'}
          disabled={recalculateSavings}
          value={annualAvoidableCost}
          onChange={handleChangeInput(setAnnualAvoidableCost)}
        />

        <NumberInput
          errors={['']}
          label={'Annual Avoidable Cooling Use:'}
          format={'n'}
          name={'annualAvoidableCoolingUse'}
          unitLabel={taskDetails.annualAvoidableCoolingUseEngUnitLabel}
          disabled={recalculateSavings}
          value={annualAvoidableCoolingUse}
          onChange={handleChangeInput(setAnnualAvoidableCoolingUse)}
        />

        <NumberInput
          errors={['']}
          label={'Annual Avoidable Electric Use:'}
          format={'n'}
          name={'annualAvoidableElectricUse'}
          unitLabel={taskDetails.annualAvoidableElectricUseEngUnitLabel}
          disabled={recalculateSavings}
          value={annualAvoidableElectricUse}
          onChange={handleChangeInput(setAnnualAvoidableElectricUse)}
        />

        <NumberInput
          errors={['']}
          label={'Annual Avoidable Heating Use:'}
          format={'n'}
          name={'annualAvoidableHeatingUse'}
          unitLabel={taskDetails.annualAvoidableHeatingUseEngUnitLabel}
          disabled={recalculateSavings}
          value={annualAvoidableHeatingUse}
          onChange={handleChangeInput(setAnnualAvoidableHeatingUse)}
        />

        <NumberInput
          errors={['']}
          label={'Annual Avoidable Carbon:'}
          format={'n'}
          name={'annualAvoidableCarbon'}
          unitLabel={taskDetails.annualAvoidableCarbonEngUnitLabel}
          disabled={recalculateSavings}
          value={annualAvoidableCarbon}
          onChange={handleChangeInput(setAnnualAvoidableCarbon)}
        />

        <NumberInput
          errors={['']}
          label={'Additional Savings:'}
          format={'n'}
          unitLabel={taskDetails.isoCurrencySymbol}
          name={'additionalSavings'}
          disabled={recalculateSavings}
          value={additionalSavings}
          onChange={handleChangeInput(setAdditionalSavings)}
        />

        {canCreateWorkOrder && <CheckboxInput
          label={'Generate Work Order:'}
          name={'hasGenerateWorkOrder'}
          checked={hasGenerateWorkOrder}
          onChange={handleChangeInput(setHasGenerateWorkOrder)}
        />}

        {canCreateWorkOrder && <LabelValue
          label="Work Order ID:"
          value={taskDetails.workOrderId}
        />}

        <LabelValue
          label="Date Created:"
          value={formatDateTime(
            { taskDetails, field: 'dateCreated' },
            { culture },
          )}
        />
        <LabelValue
          label="Last Modified:"
          value={`${taskDetails.lastModifiedBy}, ${formatDateTime(
            { taskDetails, field: 'dateModified' },
            { culture },
          )}`} />
      </div>


      <div className={'modal-footer'} style={{ display: 'flex', justifyContent: 'center', marginTop: '5px', paddingRight: '5px' }}>
        <div className="buttons">
          <button
            type={'submit'}
            className={`button is-info is-outlined is-medium ${detailsSaving ? 'is-loading' : ''}`}
            onClick={saveEdit}
          >
            Save
          </button>
          <button
            className={'button is-info is-outlined is-medium'}
            onClick={cancelCallback}
          >
            Close
          </button>
        </div>
      </div>
    </form>


  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign(
    {},
    modalActions,
    taskActions,
    buildingSettingsActions,
    { apiErrorHandler },
    { replace },
    widgetActions),
  dispatch,
  ),
});

function mapStateToProps(state) {
  return editTaskModalSelector(state);
}

EditTaskDetailsModal.propTypes = {
  taskStatuses: PropTypes.array.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  saveCallback: PropTypes.func,
  details: PropTypes.object,
  detailsSaving: PropTypes.bool,
  assigneesAndReporters: PropTypes.array,
  assigneesAndReportersLoading: PropTypes.bool,
  analysis: PropTypes.array.isRequired,
  culture: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  equipmentAnalysisAll: PropTypes.array.isRequired,
  buildingSettings: PropTypes.array.isRequired,
  equipmentAll: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTaskDetailsModal);
