/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { GridCell } from '@progress/kendo-react-grid';
import { format as kendoFormat, formatNumber, formatDate } from '@telerik/kendo-intl';
import standardDefaultFormats from '../../intl/standardDefaultFormats';
import { templateParser, isValidISODateString, formatDateWithOffset, shouldUseLocalDate, replaceLinkUrlOrigin, isLinkUrlSameOrigin, parseQueryString, htmlParser, extractTextFromHtml } from '../../../utils';

export const rendererTypes = {
  normalCell: 'normalCell',
  exportPDF: 'exportPDF',
  exportCSV: 'exportCSV',
};

const baseRenderers = {
  default: (props)=> {
    const { field, dataItem, format, valueOnly, options: { culture, datasetIDs } } = props;
    let value = dataItem[field];

    if (format) {
      value = kendoFormat(format, [value], culture);
    }

    // number
    if (!format && typeof value === 'number') {
      value = formatNumber(value, standardDefaultFormats.number, culture);
    }

    // date
    if (!format && (isValidISODateString(value) || value instanceof Date)) {
      const formatter = (value instanceof Date || shouldUseLocalDate(datasetIDs, field)) ? formatDate : formatDateWithOffset;
      value = shouldUseLocalDate(datasetIDs, field) ? new Date(value) : value;
      value = formatter(value, standardDefaultFormats.date, culture);
    }

    if (valueOnly) return value;

    const overrideDataItem = { ...dataItem, [field]: value };

    return (
      <GridCell {...props} dataItem={overrideDataItem} />
    );
  },

  date: (props) => {
    const { field, dataItem, options: { culture, datasetIDs }, cellProps = {} } = props;
    let fieldValue = dataItem[field];
    let dateFormat;
    let formatter;
    let value;
    let parsedDataItem;

    if (fieldValue) {
      dateFormat = cellProps.format ? `${cellProps.format}`.replace('{0:', '').replace('}', '') : standardDefaultFormats.date;
      formatter = (fieldValue instanceof Date || shouldUseLocalDate(datasetIDs, field)) ? formatDate : formatDateWithOffset;
      fieldValue = shouldUseLocalDate(datasetIDs, field) ? new Date(fieldValue) : fieldValue;
      value = formatter(fieldValue, dateFormat, culture);
      parsedDataItem = { ...dataItem, [field]: value };
    } else {
      parsedDataItem = dataItem;
    }

    return (
      <GridCell {...props} dataItem={parsedDataItem} />
    );
  },
};

const link = (props) => {
  const {
    rendererType, field, title, dataItem, grouped, level,
    fieldStatic, titleStatic, className, style, format, linkTemplate, valueOnly,
    options: { culture, onShowTaskDetails, onShowDiagnosticDetails },
  } = props;

  let linkHref = fieldStatic ? field : dataItem[field];
  let linkTitle = titleStatic ? title : dataItem[title];

  if (grouped && !level) return null;

  if (!titleStatic && !format) {
    linkTitle = baseRenderers.default({ ...props, field: title, valueOnly: true });
  }

  if (format) {
    linkTitle = kendoFormat(format, [linkTitle], culture);
  }

  if ([rendererTypes.exportPDF, rendererTypes.exportCSV].includes(rendererType)) {
    linkTitle = linkHref;
  }

  if (linkTemplate) {
    linkHref = templateParser(linkTemplate, dataItem);
  }
  
  if (valueOnly) {
    return linkHref;
  }
  
  const handleClick = (event) => {
    const linkHrefLowerCase = linkHref.toLowerCase();
    if (
      dataItem.taskId &&
      linkHrefLowerCase.includes('tasks') &&
      linkHrefLowerCase.includes('taskid')
    ) {
      event.preventDefault();
      onShowTaskDetails(dataItem);
    } else if (
      linkHrefLowerCase.includes('/diagnostics') &&
      linkHrefLowerCase.includes('unitid') &&
      linkHrefLowerCase.includes('bid') &&
      linkHrefLowerCase.includes('aid') &&
      linkHrefLowerCase.includes('eid') &&
      linkHrefLowerCase.includes('rng') &&
      linkHrefLowerCase.includes('sd') &&
      linkHrefLowerCase.includes('ed')
    ) {
      event.preventDefault();
      const queryObject = parseQueryString(linkHrefLowerCase);
      onShowDiagnosticDetails(queryObject);
    }
  };
  
  const linkElement = isLinkUrlSameOrigin(linkHref)
    ? (
      <Link
        to={replaceLinkUrlOrigin(linkHref)}
        title={linkTitle}
        onClick={handleClick}
      >
        {linkTitle}
      </Link>
    ) : (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={linkHref}
        title={linkTitle}
        onClick={handleClick}
      >
        {linkTitle}
      </a>
    );

  return (
    <td className={className} style={style}>
      {linkElement}
    </td>
  );
};

const taskStatus = (props) => {
  const { field, dataItem, options = {} } = props;

  if (options.renderType === 'csv' || options.renderType === 'excel') {
    return dataItem[field];
  }

  return (
    <td className="has-text-centered">
      <div
        className={classNames('task-status-badge tag', {
          'is-info': dataItem[field] === 'In Process',
          'is-link': dataItem[field] === 'Open',
          'is-success': dataItem[field] === 'Completed',
          'is-dark': dataItem[field] === 'Cancelled',
          'is-warning': dataItem[field] === 'On Hold',
        })}>
        {dataItem[field]}
      </div>
    </td>
  );
};

const html = (props) => {
  const { valueOnly, field, dataItem, className, style } = props;
  const reactElement = htmlParser(dataItem[field]);

  // Export Excel & CSV
  if (valueOnly) {
    return extractTextFromHtml(dataItem[field]);
  }

  return (
    <td className={className} style={style}>
      {reactElement}
    </td>
  );
};

const cellRenderers = { ...baseRenderers, link, taskStatus, html };

export default cellRenderers;
