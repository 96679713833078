// ERROR ACTIONS
export const LOG_RUNTIME_ERROR = 'LOG_RUNTIME_ERROR';

export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const BEGIN_THEME_CALL = 'BEGIN_THEME_CALL';
export const THEME_CALL_ERROR = 'THEME_CALL_ERROR';
export const THEME_SUCCESS = 'THEME_SUCCESS';
export const BEGIN_BUILDINGS_FILTERS_RESOURCES = 'BEGIN_BUILDINGS_FILTERS_RESOURCES';
export const BUILDINGS_FILTERS_RESOURCES_SUCCESS = 'BUILDINGS_FILTERS_RESOURCES_SUCCESS';
export const BUILDINGS_FILTERS_RESOURCES_CALL_ERROR = 'BUILDINGS_FILTERS_RESOURCES_CALL_ERROR';

// BUILDINGS FILTER ACTIONS
export const INITIALIZE_AVAILABLE_DATA = 'INITIALIZE_AVAILABLE_DATA';
export const BEGIN_BUILDINGS_CALL = 'BEGIN_BUILDINGS_CALL';
export const BUILDINGS_SUCCESS = 'BUILDINGS_SUCCESS';
export const BUILDINGS_CALL_ERROR = 'BUILDINGS_CALL_ERROR';
export const BEGIN_BUILDING_TYPES_CALL = 'BEGIN_BUILDING_TYPES_CALL';
export const BUILDING_TYPES_SUCCESS = 'BUILDING_TYPES_SUCCESS';
export const BUILDING_TYPES_CALL_ERROR = 'BUILDING_TYPES_CALL_ERROR';
export const BEGIN_BUILDING_CLASSES_CALL = 'BEGIN_BUILDING_CLASSES_CALL';
export const BUILDING_CLASSES_SUCCESS = 'BUILDING_CLASSES_SUCCESS';
export const BUILDING_CLASSES_CALL_ERROR = 'BUILDING_CLASSES_CALL_ERROR';
export const BEGIN_UNIT_BUILDING_GROUP_CALL = 'BEGIN_UNIT_BUILDING_GROUP_CALL';
export const BEGIN_UNIT_DEPLOYMENT_GROUPS_CALL = 'BEGIN_UNIT_DEPLOYMENT_GROUPS_CALL';
export const UNIT_DEPLOYMENT_GROUPS_SUCCESS = 'UNIT_DEPLOYMENT_GROUPS_SUCCESS';
export const UNIT_DEPLOYMENT_GROUPS_CALL_ERROR = 'UNIT_DEPLOYMENT_GROUPS_CALL_ERROR';
export const UNIT_BUILDING_GROUPS_SUCCESS = 'UNIT_BUILDING_GROUPS_SUCCESS';
export const UNIT_BUILDING_GROUPS_CALL_ERROR = 'UNIT_BUILDING_GROUPS_CALL_ERROR';
export const BEGIN_BUILDING_GROUP_BUILDINGS_CALL = 'BEGIN_UNIT_BUILDING_GROUP_BUILDINGS_CALL';
export const BUILDING_GROUP_BUILDINGS_SUCCESS = 'BUILDING_GROUP_BUILDINGS_SUCCESS';
export const BUILDING_GROUP_BUILDINGS_CALL_ERROR = 'BUILDING_GROUP_BUILDINGS_CALL_ERROR';
export const BEGIN_RESOURCES_CALL = 'BEGIN_RESOURCES_CALL';
export const RESOURCES_SUCCESS = 'RESOURCES_CALL_SUCCESS';
export const RESOURCES_CALL_ERROR = 'RESOURCES_CALL_ERROR';
export const BEGIN_UNITS_CALL = 'BEGIN_UNITS_CALL';
export const UNITS_SUCCESS = 'UNITS_SUCCESS';
export const UNITS_CALL_ERROR = 'UNITS_CALL_ERROR';
export const PROVIDERS_SUCCESS = 'PROVIDERS_SUCCESS';

// BUILDING SETTINGS ACTIONS
export const BEGIN_BUILDING_SETTINGS_CALL = 'BEGIN_BUILDING_SETTINGS_CALL';
export const BUILDING_SETTINGS_SUCCESS = 'BUILDING_SETTINGS_SUCCESS';
export const BUILDING_SETTINGS_CALL_ERROR = 'BUILDING_SETTINGS_CALL_ERROR';
export const CLEAR_BUILDING_SETTINGS = 'CLEAR_BUILDING_SETTINGS';

export const LOAD_LINECHARTS_SUCCESS = 'LOAD_LINECHARTS_SUCCESS';
export const POSITION_LEGEND = 'POSITION_LEGEND';

// FILTEr ACTIONS
export const SET_CURRENT_FILTER_FIELD = 'SET_CURRENT_FILTER_FIELD';
export const CLEAR_DASHBOARD_FILTERS = 'CLEAR_DASHBOARD_FILTERS';
export const SET_EXCLUDE_PAGE_FILTER_IDS = 'SET_EXCLUDE_PAGE_FILTER_IDS';
export const SET_FILTER_OPEN = 'SET_FILTER_OPEN';

// DASHBOARD ACTIONS
export const DASHBOARD_NAMES_SUCCESS = 'DASHBOARD_NAMES_SUCCESS';
export const CURRENT_DASHBOARD_RESET = 'CURRENT_DASHBOARD_RESET';
export const BEGIN_DASHBOARDS_CALL = 'BEGIN_DASHBOARDS_CALL';
export const DASHBOARDS_SUCCESS = 'DASHBOARDS_SUCCESS';
export const DASHBOARDS_CALL_ERROR = 'DASHBOARDS_CALL_ERROR';
export const SET_LANDING_PAGE = 'SET_LANDING_PAGE';
export const BEGIN_DASHBOARD_CALL = 'BEGIN_DASHBOARD_CALL';
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';
export const DASHBOARD_CALL_ERROR = 'DASHBOARD_CALL_ERROR';
export const BEGIN_ORG_DASHBOARD_TEMPLATE_CALL = 'BEGIN_ORG_DASHBOARD_TEMPLATE_CALL';
export const ORG_DASHBOARD_TEMPLATE_SAVE_SUCCESS = 'ORG_DASHBOARD_TEMPLATE_SAVE_SUCCESS';
export const ORG_DASHBOARD_TEMPLATE_SUCCESS = 'ORG_DASHBOARD_TEMPLATE_SUCCESS';
export const ORG_DASHBOARD_TEMPLATE_CALL_ERROR = 'ORG_DASHBOARD_TEMPLATE_CALL_ERROR';
export const BEGIN_DASHBOARD_TEMPLATE_CALL = 'BEGIN_DASHBOARD_TEMPLATE_CALL';
export const DASHBOARD_TEMPLATE_SUCCESS = 'DASHBOARD_TEMPLATE_SUCCESS';
export const DASHBOARD_TEMPLATE_CALL_ERROR = 'DASHBOARD_TEMPLATE_CALL_ERROR';
export const BEGIN_DASHBOARD_TEMPLATES_CALL = 'BEGIN_DASHBOARD_TEMPLATES_CALL';
export const DASHBOARD_TEMPLATES_SUCCESS = 'DASHBOARD_TEMPLATES_SUCCESS';
export const DASHBOARD_TEMPLATES_CALL_ERROR = 'DASHBOARD_TEMPLATES_CALL_ERROR';
export const BEGIN_DASHBOARD_CREATE_CALL = 'BEGIN_DASHBOARD_CREATE_CALL';
export const BEGIN_DASHBOARD_CLONE_CALL = 'BEGIN_DASHBOARD_CLONE_CALL';
export const DASHBOARD_CLONE_CALL_ERROR = 'DASHBOARD_CLONE_CALL_ERROR';
export const DASHBOARD_CREATE_SUCCESS = 'DASHBOARD_CREATE_SUCCESS';
export const DASHBOARD_CREATE_CALL_ERROR = 'DASHBOARD_CREATE_CALL_ERROR';
export const BEGIN_REMOVE_DASHBOARD_DEFAULT = 'BEGIN_REMOVE_DASHBOARD_DEFAULT';
export const REMOVE_DASHBOARD_DEFAULT_SUCCESS = 'REMOVE_DASHBOARD_DEFAULT_SUCCESS';
export const REMOVE_DASHBOARD_DEFAULT_ERROR = 'REMOVE_DASHBOARD_DEFAULT_ERROR';
export const BEGIN_DASHBOARD_SAVE_CALL = 'BEGIN_DASHBOARD_SAVE_CALL';
export const DASHBOARD_SAVE_SUCCESS = 'DASHBOARD_SAVE_SUCCESS';
export const DASHBOARD_SAVE_CALL_ERROR = 'DASHBOARD_CREATE_CALL_ERROR';
export const BEGIN_CREATE_DASHBOARD_TEMPLATE_CALL = 'BEGIN_CREATE_DASHBOARD_TEMPLATE_CALL';
export const CREATE_DASHBOARD_TEMPLATE_ERROR = 'CREATE_DASHBOARD_TEMPLATE_ERROR';
export const CREATE_DASHBOARD_TEMPLATE_SUCCESS = 'CREATE_DASHBOARD_TEMPLATE_SUCCESS';
export const BEGIN_DASHBOARD_TEMPLATE_SAVE_CALL = 'BEGIN_DASHBOARD_TEMPLATE_SAVE_CALL';
export const DASHBOARD_TEMPLATE_SAVE_SUCCESS = 'DASHBOARD_TEMPLATE_SAVE_SUCCESS';
export const DASHBOARD_TEMPLATE_SAVE_ERROR = 'DASHBOARD_TEMPLATE_SAVE_ERROR';
export const DELETE_ORG_DASHBOARD_TEMPLATE_SUCCESS = 'DELETE_ORG_DASHBOARD_TEMPLATE_SUCCESS';
export const DELETE_ORG_DASHBOARD_TEMPLATE_ERROR = 'DELETE_ORG_DASHBOARD_TEMPLATE_ERROR';
export const BEGIN_DELETE_ORG_DASHBOARD_TEMPLATE_CALL = 'BEGIN_DELETE_ORG_DASHBOARD_TEMPLATE_CALL';
export const BEGIN_DELETE_DASHBOARD_TEMPLATE_CALL = 'BEGIN_DELETE_DASHBOARD_TEMPLATE_CALL';
export const DELETE_DASHBOARD_TEMPLATE_SUCCESS = 'DELETE_DASHBOARD_TEMPLATE_SUCCESS';
export const DELETE_DASHBOARD_TEMPLATE_ERROR = 'DELETE_DASHBOARD_TEMPLATE_ERROR';
export const ADD_OR_UPDATE_DASHBOARD_WIDGET = 'ADD_OR_UPDATE_DASHBOARD_WIDGET';
export const REMOVE_DASHBOARD_WIDGET = 'REMOVE_DASHBOARD_WIDGET';

export const BEGIN_DASHBOARD_TEMPLATE_ASSIGN_CALL = 'BEGIN_DASHBOARD_TEMPLATE_ASSIGN_CALL';
export const DASHBOARD_TEMPLATE_ASSIGN_SUCCESS = 'DASHBOARD_TEMPLATE_ASSIGN_SUCCESS';
export const DASHBOARD_TEMPLATE_UNASSIGN_SUCCESS = 'DASHBOARD_TEMPLATE_UNASSIGN_SUCCESS';
export const DASHBOARD_TEMPLATE_ASSIGN_ERROR = 'DASHBOARD_TEMPLATE_ASSIGN_ERROR';

export const BEGIN_GET_DASHBOARD_TEMPLATE_PERMISSIONS = 'BEGIN_GET_DASHBOARD_TEMPLATE_PERMISSIONS';
export const GET_DASHBOARD_TEMPLATE_PERMISSIONS_SUCCESS = 'GET_DASHBOARD_TEMPLATE_PERMISSIONS_SUCCESS';
export const GET_DASHBOARD_TEMPLATE_PERMISSIONS_ERROR = 'GET_DASHBOARD_TEMPLATE_PERMISSIONS_ERROR';

export const BEGIN_DELETE_DASHBOARD = 'BEGIN_DELETE_DASHBOARD';
export const DELETE_DASHBOARD_SUCCESS = 'DELETE_DASHBOARD_SUCCESS';
export const DELETE_DASHBOARD_CALL_ERROR = 'DELETE_DASHBOARD_CALL_ERROR';

export const BEGIN_LOAD_DASHBOARD = 'BEGIN_LOAD_DASHBOARD';
export const LOAD_DASHBOARD_SUCCESS = 'LOAD_DASHBOARD_SUCCESS';
export const LOAD_DASHBOARD_CALL_ERROR = 'LOAD_DASHBOARD_CALL_ERROR';

export const GET_DASHBOARD_PERMISSION_SUCCESS = 'GET_DASHBOARD_PERMISSION_SUCCESS';
export const GET_DASHBOARD_PERMISSION_ERROR = 'GET_DASHBOARD_PERMISSION_ERROR';

export const GET_BUILDING_PERMISSION_SUCCESS = 'GET_BUILDING_PERMISSION_SUCCESS';
export const GET_BUILDING_PERMISSION_ERROR = 'GET_BUILDING_PERMISSION_ERROR';

export const GET_BUILDING_BY_ID_PERMISSION_SUCCESS = 'GET_BUILDING_BY_ID_PERMISSION_SUCCESS';
export const GET_BUILDING_BY_ID_PERMISSION_ERROR = 'GET_BUILDING_BY_ID_PERMISSION_ERROR';
export const CLEAR_BUILDING_BY_ID_PERMISSION = 'CLEAR_BUILDING_BY_ID_PERMISSION';

export const GET_EQUIPMENT_PERMISSION_SUCCESS = 'GET_EQUIPMENT_PERMISSION_SUCCESS';
export const GET_EQUIPMENT_PERMISSION_ERROR = 'GET_EQUIPMENT_PERMISSION_ERROR';

// DASHBOARD FILTERS
export const SET_DASHBOARD_FILTERS_LOADED = 'SET_DASHBOARD_FILTERS_LOADED';
export const ADD_OR_UPDATE_JSON_FILTERS = 'ADD_OR_UPDATE_JSON_FILTERS';
export const REMOVE_JSON_FILTERS = 'REMOVE_JSON_FILTERS';

export const SET_WIDGET_FILTERS_LOADED = 'SET_WIDGET_FILTERS_LOADED';

export const GET_EQUIPMENT_CLASSES_SUCCESS = 'GET_EQUIPMENT_CLASSES_SUCCESS';
export const GET_EQUIPMENT_TYPES_SUCCESS = 'GET_EQUIPMENT_TYPES_SUCCESS';
export const GET_EQUIPMENTS_SUCCESS = 'GET_EQUIPMENTS_SUCCESS';

export const GET_POINT_CLASSES_SUCCESS = 'GET_POINT_CLASSES_SUCCESS';
export const GET_POINT_TYPES_SUCCESS = 'GET_POINT_TYPES_SUCCESS';
export const GET_POINTS_SUCCESS = 'GET_POINTS_SUCCESS';
export const GET_V_POINTS_SUCCESS = 'GET_V_POINTS_SUCCESS';

export const GET_POINT_LOOKUP_CLASSES_SUCCESS = 'GET_POINT_LOOKUP_CLASSES_SUCCESS';
export const GET_POINT_LOOKUP_TYPES_SUCCESS = 'GET_POINT_LOOKUP_TYPES_SUCCESS';

export const FILTER_EQUIPMENT_TYPES = 'FILTER_EQUIPMENT_TYPES';
export const FILTER_EQUIPMENTS = 'FILTER_EQUIPMENTS';

export const GET_EQUIPMENT_LOOKUP_CLASSES_SUCCESS = 'GET_EQUIPMENT_LOOKUP_CLASSES_SUCCESS';
export const GET_EQUIPMENT_LOOKUP_TYPES_SUCCESS = 'GET_EQUIPMENT_LOOKUP_TYPES_SUCCESS';
export const GET_EQUIPMENT_LOOKUP_EQUIPMENTS_SUCCESS = 'GET_EQUIPMENT_LOOKUP_EQUIPMENTS_SUCCESS';
export const GET_EQUIPMENT_LOOKUP_CONFIGURATION_STATUSES_SUCCESS = 'GET_EQUIPMENT_LOOKUP_CONFIGURATION_STATUSES_SUCCESS';

export const GET_TASK_STATUSES_SUCCESS = 'GET_TASK_STATUSES_SUCCESS';

export const GET_TASK_ASSIGNEES_SUCCESS = 'GET_TASK_ASSIGNEES_SUCCESS';
export const FILTER_TASK_ASSIGNEES = 'FILTER_TASK_ASSIGNEES';

export const GET_TASK_REPORTERS_SUCCESS = 'GET_TASK_REPORTERS_SUCCESS';
export const FILTER_TASK_REPORTERS = 'FILTER_TASK_REPORTERS';

export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const FILTER_CURRENCIES = 'FILTER_CURRENCIES';

export const SET_GROUP_DROPDOWN_VALUES = 'SET_GROUP_DROPDOWN_VALUES';
export const SET_GLOBAL_GROUP_DROPDOWN_VALUES = 'SET_GLOBAL_GROUP_DROPDOWN_VALUES';
export const SET_BUILDINGS_GROUP_DROPDOWN_VALUES = 'SET_BUILDINGS_GROUP_DROPDOWN_VALUES';
export const SET_EQUIPMENTS_GROUP_DROPDOWN_VALUES = 'SET_EQUIPMENTS_GROUP_DROPDOWN_VALUES';
export const SET_GLOBAL_FILTERS_CHANGED = 'SET_GLOBAL_FILTERS_CHANGED';

export const GET_DATA_SOURCES_SUCCESS = 'GET_DATA_SOURCES_SUCCESS';
export const FILTER_DATA_SOURCES = 'FILTER_DATA_SOURCES';

export const SET_ENABLE_DATE_INTERVAL_CHANGE = 'SET_ENABLE_DATE_INTERVAL_CHANGE';

// DATASET
export const SET_WIDGET_QUERY_STRING = 'SET_WIDGET_QUERY_STRING';
export const SET_WIDGET_LOADING = 'SET_WIDGET_LOADING';
export const SET_WIDGET_RELOAD = 'SET_WIDGET_RELOAD';
export const GET_ANALYSES_SUCCESS = 'GET_ANALYSES_SUCCESS';
export const FILTER_ANALYSES = 'FILTER_ANALYSES';

export const GET_EQUIPMENT_ANALYSES_SUCCESS = 'GET_EQUIPMENT_ANALYSES_SUCCESS';


/// Email Templates
export const GET_EMAIL_TEMPLATES_REQUEST = 'GET_EMAIL_TEMPLATES_REQUEST';
export const GET_EMAIL_TEMPLATES_SUCCESS = 'GET_EMAIL_TEMPLATES_SUCCESS';
export const GET_EMAIL_TEMPLATES_FAILURE = 'EMAIL_TEMPLATES_FAILURE';

export const GET_EMAIL_TEMPLATE_ASSIGNED_USERS_SUCCESS = 'GET_EMAIL_TEMPLATE_ASSIGNED_USERS_SUCCESS';
export const GET_EMAIL_TEMPLATE_ASSIGNED_USERS_FAILURE = 'GET_EMAIL_TEMPLATE_ASSIGNED_USERS_FAILURE';

export const BEGIN_ASSIGN_EMAIL_TEMPLATES_TO_USER = 'BEGIN_ASSIGN_EMAIL_TEMPLATES_TO_USER';
export const ASSIGN_USERS_EMAIL_TEMPLATES_SUCCESS = 'ASSIGN_USERS_EMAIL_TEMPLATES_SUCCESS';
export const ASSIGN_EMAIL_TEMPLATES_TO_USER_FAILURE = 'ASSIGN_EMAIL_TEMPLATES_TO_USER_FAILURE';

// STATUS FILTERS
export const SET_LOADING_FILTERS = 'SET_LOADING_FILTERS';
export const SET_CALCULATING_FILTERS = 'SET_CALCULATING_FILTERS';

// LOGIN ACTIONS
export const LOAD_LOCATION_PROPS_SUCCESS = 'LOAD_LOCATION_PROPS_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const BEGIN_USER_CALL = 'BEGIN_USER_CALL';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_CALL_ERROR = 'USER_CALL_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const BEGIN_RENEW_CALL = 'BEGIN_RENEW_CALL';
export const RENEW_SUCCESS = 'RENEW_SUCCESS';
export const RENEW_CALL_ERROR = 'RENEW_CALL_ERROR';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_FAILED = 'AUTHENTICATE_FAILED';
export const AUTHENTICATING = 'AUTHENTICATING';
export const SET_IDP_REDIRECT = 'SET_IDP_REDIRECT';
export const SET_IDP_LOGOUT = 'SET_IDP_LOGOUT';
export const SET_IDP_SELECTABLE = 'SET_IDP_SELECTABLE';
export const SET_LAST_API_CALL_TIME = 'SET_LAST_API_CALL_TIME';
export const AUTH_USER_APP_INITIALIZED = 'AUTH_USER_APP_INITIALIZED';

// USER Actions
export const BEGIN_EDIT_USER = 'BEGIN_EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR';
export const GET_USER_CURRENCIES = 'GET_USER_CURRENCIES';
export const GET_USER_CURRENCIES_SUCCESS = 'GET_USER_CURRENCIES_SUCCESS';
export const SET_GLOBAL_JSON_FILTERS = 'SET_GLOBAL_JSON_FILTERS';
export const INITIALIZE_GLOBAL_JSON_FILTERS = 'INITIALIZE_GLOBAL_JSON_FILTERS';
export const RE_INITIALIZE_GLOBAL_JSON_FILTERS = 'RE_INITIALIZE_GLOBAL_JSON_FILTERS';

export const BEGIN_SAVE_GLOBAL_FILTERS = 'BEGIN_SAVE_GLOBAL_FILTERS';
export const SAVE_GLOBAL_FILTERS_SUCCESS = 'SAVE_GLOBAL_FILTERS_SUCCESS';
export const SAVE_GLOBAL_FILTERS_ERROR = 'SAVE_GLOBAL_FILTERS_ERROR';

export const GET_MODULE_CONFIG = 'GET_MODULE_CONFIG';
export const GET_MODULE_CONFIG_SUCCESS = 'GET_MODULE_CONFIG_SUCCESS';

// CONTACT Actions
export const BEGIN_ADD_CONTACT = 'BEGIN_ADD_CONTACT';
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';
export const ADD_CONTACT_ERROR = 'ADD_CONTACT_ERROR';

// UI Actions
export const TOGGLE_LEFTNAV = 'TOGGLE_LEFTNAV';
export const TOGGLE_RIGHTNAV = 'TOGGLE_RIGHTNAV';
export const UPDATE_FILTER_SELECTED = 'UPDATE_FILTER_SELECTED';
export const UPDATE_FILTERS_SELECTED = 'UPDATE_FILTERS_SELECTED';
export const UPDATE_FILTER_VISIBILITY = 'UPDATE_FILTER_VISIBILITY';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const UPDATE_MODAL = 'UPDATE_MODAL';
export const ADD_MODAL = 'ADD_MODAL';
export const REMOVE_MODAL = 'REMOVE_MODAL';

// Widget Actions
export const SET_PREVIOUS_TO_CURRENT = 'SET_PREVIOUS_TO_CURRENT';
export const UPDATE_CROSS_FILTER = 'UPDATE_CROSS_FILTER';
export const BEGIN_WIDGET_CATEGORIES_CALL = 'BEGIN_WIDGET_CATEGORIES_CALL';
export const WIDGET_CATEGORIES_SUCCESS = 'WIDGET_CATEGORIES_SUCCESS';
export const WIDGET_CATEGORIES_CALL_ERROR = 'WIDGET_CATEGORIES_CALL_ERROR';
export const BEGIN_WIDGET_DATASETS_CALL = 'BEGIN_WIDGET_DATASETS_CALL';
export const WIDGET_DATASETS_SUCCESS = 'WIDGET_DATASETS_SUCCESS';
export const WIDGET_DATASETS_CALL_ERROR = 'WIDGET_DATASETS_CALL_ERROR';
export const BEGIN_WIDGET_ANALYSIS_TASKS_CALL = 'BEGIN_WIDGET_ANALYSIS_TASKS_CALL';
export const WIDGET_ANALYSIS_TASKS_SUCCESS = 'WIDGET_ANALYSIS_TASKS_SUCCESS';
export const WIDGET_ANALYSIS_TASKS_CALL_ERROR = 'WIDGET_ANALYSIS_TASKS_CALL_ERROR';
export const BEGIN_WIDGET_SUMMARIES_CALL = 'BEGIN_WIDGET_SUMMARIES_CALL';
export const WIDGET_SUMMARIES_SUCCESS = 'WIDGET_SUMMARIES_SUCCESS';
export const WIDGET_SUMMARIES_CALL_ERROR = 'WIDGET_SUMMARIES_CALL_ERROR';
export const BEGIN_WIDGET_TYPES_CALL = 'BEGIN_WIDGET_TYPES_CALL';
export const WIDGET_TYPES_SUCCESS = 'WIDGET_TYPES_SUCCESS';
export const WIDGET_TYPES_CALL_ERROR = 'WIDGET_TYPES_CALL_ERROR';
export const BEGIN_ADD_WIDGET_CALL = 'BEGIN_ADD_WIDGET_CALL';
export const ADD_WIDGET_SUCCESS = 'ADD_WIDGET_SUCCESS';
export const ADD_WIDGET_CALL_ERROR = 'ADD_WIDGET_CALL_ERROR';
export const BEGIN_DELETE_WIDGET_CALL = 'BEGIN_DELETE_WIDGET_CALL';
export const DELETE_WIDGET_SUCCESS = 'DELETE_WIDGET_SUCCESS';
export const DELETE_WIDGET_CALL_ERROR = 'DELETE_WIDGET_CALL_ERROR';
export const BEGIN_EDIT_WIDGET_CALL = 'BEGIN_EDIT_WIDGET_CALL';
export const EDIT_WIDGET_SUCCESS = 'EDIT_WIDGET_SUCCESS';
export const EDIT_WIDGET_CALL_ERROR = 'EDIT_WIDGET_CALL_ERROR';
export const BEGIN_WIDGET_DETAILS_CALL = 'BEGIN_WIDGET_DETAILS_CALL';
export const WIDGET_DETAILS_SUCCESS = 'WIDGET_DETAILS_SUCCESS';
export const WIDGET_DETAILS_CALL_ERROR = 'WIDGET_DETAILS_CALL_ERROR';
export const BEGIN_ADMIN_DELETE_WIDGET_CALL = 'BEGIN_ADMIN_DELETE_WIDGET_CALL';
export const ADMIN_DELETE_WIDGET_SUCCESS = 'ADMIN_DELETE_WIDGET_SUCCESS';
export const ADMIN_DELETE_WIDGET_CALL_ERROR = 'ADMIN_DELETE_WIDGET_CALL_ERROR';
export const ADMIN_WIDGETS_DETAILS_RESET = 'ADMIN_WIDGETS_DETAILS_RESET';

export const BEGIN_CLONE_WIDGET_CALL = 'BEGIN_CLONE_WIDGET_CALL';
export const CLONE_WIDGET_SUCCESS = 'CLONE_WIDGET_SUCCESS';
export const CLONE_WIDGET_CALL_ERROR = 'CLONE_WIDGET_CALL_ERROR';

export const SET_CURRENT_WIDGET = 'SET_CURRENT_WIDGET';

// Task Module Actions
export const SET_TASK_MODULE_LOADING = 'SET_TASK_MODULE_LOADING';
export const GET_TASK_MODULE_SUCCESS = 'GET_TASK_MODULE_SUCCESS';
export const CLEAR_TASK_MODULE_LIST = 'CLEAR_TASK_MODULE_LIST';
export const UPDATE_TASK_MODULE_GRID_CONFIG = 'UPDATE_TASK_MODULE_GRID_CONFIG';

export const GET_REPORTERS_AND_ASSIGNEES = 'GET_REPORTERS_AND_ASSIGNEES';
export const SET_REPORTERS_AND_ASSIGNEES_LOADING = 'SET_REPORTERS_AND_ASSIGNEES_LOADING';
export const GET_REPORTERS_AND_ASSIGNEES_SUCCESS = 'GET_REPORTERS_AND_ASSIGNEES_SUCCESS';
export const CLEAR_REPORTERS_AND_ASSIGNEES = 'CLEAR_REPORTERS_AND_ASSIGNEES';

export const SET_TASK_DETAILS_LOADING = 'SET_TASK_DETAILS_LOADING';
export const GET_TASK_DETAILS_SUCCESS = 'GET_TASK_DETAILS_SUCCESS';
export const CLEAR_TASK_DETAILS = 'CLEAR_TASK_DETAILS';

export const SET_CREATE_TASK_SAVING = 'SET_CREATE_TASK_SAVING';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const SET_EDIT_TASK_DETAILS_SAVING = 'SET_EDIT_TASK_DETAILS_SAVING';
export const EDIT_TASK_DETAILS_SUCCESS = 'EDIT_TASK_DETAILS_SUCCESS';
export const SET_TASK_PERMISSIONS_LOADING = 'SET_TASK_PERMISSIONS_LOADING';
export const GET_TASK_PERMISSIONS_SUCCESS = 'GET_TASK_PERMISSIONS_SUCCESS';

export const SET_TASK_MODULE_DELETING = 'SET_TASK_MODULE_DELETING';
export const DELETE_TASK_MODULE_SUCCESS = 'DELETE_TASK_MODULE_SUCCESS';

export const SET_TASK_MODULE_BULK_DELETING = 'SET_TASK_MODULE_BULK_DELETING';
export const BULK_DELETE_TASK_MODULE_SUCCESS = 'BULK_DELETE_TASK_MODULE_SUCCESS';

export const SET_TASK_MODULE_BULK_EDITING = 'SET_TASK_MODULE_BULK_EDITING';
export const BULK_EDIT_TASK_MODULE_SUCCESS = 'BULK_EDIT_TASK_MODULE_SUCCESS';

export const UPDATE_TASK_MODULE_CONFIG_COLUMNS = 'UPDATE_TASK_MODULE_CONFIG_COLUMNS';

export const SET_TASK_MODULE_CONFIG_SAVING = 'SET_TASK_MODULE_CONFIG_SAVING';
export const UPDATE_TASK_MODULE_CONFIG_SUCCESS = 'UPDATE_TASK_MODULE_CONFIG_SUCCESS';
export const RESET_TASK_MODULE_JSON_FILTERS = 'RESET_TASK_MODULE_JSON_FILTERS';

export const GET_TASK_PERMISSIONS_BY_UNIT_ID_SUCCESS = 'GET_TASK_PERMISSIONS_BY_UNIT_ID_SUCCESS';

// Repropcess Diagnostics Actions or Scheduled Analyses Actions
export const GET_SCHEDULED_ANALYSES_PERMISSIONS_BY_UNIT_ID_SUCCESS = 'GET_SCHEDULED_ANALYSES_PERMISSIONS_BY_UNIT_ID_SUCCESS';

// Diagnostics Module Actions
export const SET_DIAGNOSTICS_MODULE_LOADING = 'SET_DIAGNOSTICS_MODULE_LOADING';
export const GET_DIAGNOSTICS_MODULE_SUCCESS = 'GET_DIAGNOSTICS_MODULE_SUCCESS';
export const CLEAR_DIAGNOSTICS_MODULE = 'CLEAR_DIAGNOSTICS_MODULE';
export const UPDATE_DIAGNOSTICS_MODULE_GRID_CONFIG = 'UPDATE_DIAGNOSTICS_MODULE_GRID_CONFIG';
export const UPDATE_DIAGNOSTICS_MODULE_CONFIG_COLUMNS = 'UPDATE_DIAGNOSTICS_MODULE_CONFIG_COLUMNS';
export const UPDATE_DIAGNOSTICS_MODULE_CONFIG_TOP_FILTER = 'UPDATE_DIAGNOSTICS_MODULE_CONFIG_TOP_FILTER';

export const SET_DIAGNOSTICS_MODULE_CONFIG_SAVING = 'SET_DIAGNOSTICS_MODULE_CONFIG_SAVING';
export const UPDATE_DIAGNOSTICS_MODULE_CONFIG_SUCCESS = 'UPDATE_DIAGNOSTICS_MODULE_CONFIG_SUCCESS';
export const RESET_DIAGNOSTICS_MODULE_JSON_FILTERS = 'RESET_DIAGNOSTICS_MODULE_JSON_FILTERS';

export const INITIALIZE_DIAGNOSTIC_DETAILS = 'INITIALIZE_DIAGNOSTIC_DETAILS';
export const BEGIN_DIAGNOSTIC_DETAILS_CALL = 'BEGIN_DIAGNOSTIC_DETAILS_CALL';
export const GET_DIAGNOSTIC_DETAILS_SUCCESS = 'GET_DIAGNOSTIC_DETAILS_SUCCESS';
export const DIAGNOSTIC_DETAILS_CALL_ERROR = 'DIAGNOSTIC_DETAILS_CALL_ERROR';

export const GET_EQUIPMENT_VARIABLES_PERMISSION_SUCCESS = 'GET_EQUIPMENT_VARIABLES_PERMISSION_SUCCESS';
export const GET_EQUIPMENT_VARIABLES_PERMISSION_ERROR = 'GET_EQUIPMENT_VARIABLES_PERMISSION_ERROR';

export const GET_EQUIPMENT_VARIABLES_PERMISSION_BY_UNIT_ID_SUCCESS = 'GET_EQUIPMENT_VARIABLES_PERMISSION_BY_UNIT_ID_SUCCESS';
export const GET_EQUIPMENT_VARIABLES_PERMISSION_BY_UNIT_ID_ERROR = 'GET_EQUIPMENT_VARIABLES_PERMISSION_BY_UNIT_ID_ERROR';

export const UPDATE_REPROCESS_DIAGNOSTICS_LOADING = 'UPDATE_REPROCESS_DIAGNOSTICS_LOADING';
export const UPDATE_REPROCESS_DIAGNOSTICS_SUCCESS = 'UPDATE_REPROCESS_DIAGNOSTICS_SUCCESS';
export const UPDATE_REPROCESS_DIAGNOSTICS_ERROR = 'UPDATE_REPROCESS_DIAGNOSTICS_ERROR';

// Diagnostics Preview Module Actions
export const SET_DIAGNOSTICS_PREVIEW_MODULE_LOADING = 'SET_DIAGNOSTICS_PREVIEW_MODULE_LOADING';
export const SET_DIAGNOSTICS_PREVIEW_DETAIL_LOADING = 'SET_DIAGNOSTICS_PREVIEW_DETAIL_LOADING';
export const GET_DIAGNOSTICS_PREVIEW_MODULE_SUCCESS = 'GET_DIAGNOSTICS_PREVIEW_MODULE_SUCCESS';
export const GET_DIAGNOSTICS_PREVIEW_DETAIL_SUCCESS = 'GET_DIAGNOSTICS_PREVIEW_DETAIL_SUCCESS';
export const CLEAR_DIAGNOSTICS_PREVIEW_MODULE = 'CLEAR_DIAGNOSTICS_PREVIEW_MODULE';
export const UPDATE_DIAGNOSTICS_PREVIEW_MODULE_GRID_CONFIG = 'UPDATE_DIAGNOSTICS_PREVIEW_MODULE_GRID_CONFIG';
export const UPDATE_DIAGNOSTICS_PREVIEW_MODULE_CONFIG_COLUMNS = 'UPDATE_DIAGNOSTICS_PREVIEW_MODULE_CONFIG_COLUMNS';
export const UPDATE_DIAGNOSTICS_PREVIEW_MODULE_CONFIG_TOP_FILTER = 'UPDATE_DIAGNOSTICS_PREVIEW_MODULE_CONFIG_TOP_FILTER';

export const SET_DIAGNOSTICS_PREVIEW_MODULE_CONFIG_SAVING = 'SET_DIAGNOSTICS_PREVIEW_MODULE_CONFIG_SAVING';
export const UPDATE_DIAGNOSTICS_PREVIEW_MODULE_CONFIG_SUCCESS = 'UPDATE_DIAGNOSTICS_PREVIEW_MODULE_CONFIG_SUCCESS';
export const RESET_DIAGNOSTICS_PREVIEW_MODULE_JSON_FILTERS = 'RESET_DIAGNOSTICS_PREVIEW_MODULE_JSON_FILTERS';

//Equipment Dataset
export const GET_EQUIPMENT_DATASET_LOADING='GET_EQUIPMENT_DATASET_LOADING';
export const GET_EQUIPMENT_DATASET_SUCCESS='GET_EQUIPMENT_DATASET_SUCCESS';
export const GET_EQUIPMENT_DATASET_ERROR='GET_EQUIPMENT_DATASET_ERROR';
export const CLEAR_EQUIPMENT_DATASET='CLEAR_EQUIPMENT_DATASET';

//Edit Equipment Flags
export const EDIT_EQUIPMENT_FLAGS_LOADING='EDIT_EQUIPMENT_FLAGS_LOADING';
export const EDIT_EQUIPMENT_FLAGS_SUCCESS='EDIT_EQUIPMENT_FLAGS_SUCCESS';
export const EDIT_EQUIPMENT_FLAGS_ERROR='EDIT_EQUIPMENT_FLAGS_ERROR';

// Equipment Profile
export const GET_DIAGNOSTIC_REPORT_EQUIPMENT_SUCCESS = 'GET_DIAGNOSTIC_REPORT_EQUIPMENT_SUCCESS';
export const GET_DIAGNOSTIC_REPORT_EQUIPMENT_VARIABLES_SUCCESS = 'GET_DIAGNOSTIC_REPORT_EQUIPMENT_VARIABLES_SUCCESS';
export const GET_DIAGNOSTIC_REPORT_EQUIPMENT_POINTS_SUCCESS = 'GET_DIAGNOSTIC_REPORT_EQUIPMENT_POINTS_SUCCESS';
export const GET_DIAGNOSTIC_REPORT_EQUIPMENT_BUILDING_VARIABLES_SUCCESS = 'GET_DIAGNOSTIC_REPORT_EQUIPMENT_BUILDING_VARIABLES_SUCCESS';
export const GET_DIAGNOSTIC_REPORT_ASSOCIATED_EQUIPMENT_VARIABLES_SUCCESS = 'GET_DIAGNOSTIC_REPORT_ASSOCIATED_EQUIPMENT_VARIABLES_SUCCESS';
export const GET_DIAGNOSTIC_REPORT_ASSOCIATED_EQUIPMENT_SUCCESS = 'GET_DIAGNOSTIC_REPORT_ASSOCIATED_EQUIPMENT_SUCCESS';
export const GET_DIAGNOSTIC_REPORT_ASSOCIATED_EQUIPMENT_POINTS_SUCCESS = 'GET_DIAGNOSTIC_REPORT_ASSOCIATED_EQUIPMENT_POINTS_SUCCESS';
export const CLEAR_DIAGNOSTIC_EQUIPMENT_PROFILE = 'CLEAR_DIAGNOSTIC_EQUIPMENT_PROFILE';

// Get Equipment Variables List
export const GET_EQUIPMENT_VARIABLES_LIST_LOADING = 'GET_EQUIPMENT_VARIABLES_LIST_LOADING';
export const GET_EQUIPMENT_VARIABLES_LIST_SUCCESS = 'GET_EQUIPMENT_VARIABLES_LIST_SUCCESS';
export const GET_EQUIPMENT_VARIABLES_LIST_ERROR = 'GET_EQUIPMENT_VARIABLES_LIST_ERROR';

// Create Equipment Variable
export const CREATE_EQUIPMENT_VARIABLES_VALUE_LOADING = 'CREATE_EQUIPMENT_VARIABLES_VALUE_LOADING';
export const CREATE_EQUIPMENT_VARIABLES_VALUE_SUCCESS = 'CREATE_EQUIPMENT_VARIABLES_VALUE_SUCCESS';
export const CREATE_EQUIPMENT_VARIABLES_VALUE_ERROR = 'CREATE_EQUIPMENT_VARIABLES_VALUE_ERROR';

// Update Equipment Variable
export const UPDATE_EQUIPMENT_VARIABLES_VALUE_LOADING = 'UPDATE_EQUIPMENT_VARIABLES_VALUE_LOADING';
export const UPDATE_EQUIPMENT_VARIABLES_VALUE_SUCCESS = 'UPDATE_EQUIPMENT_VARIABLES_VALUE_SUCCESS';
export const UPDATE_EQUIPMENT_VARIABLES_VALUE_ERROR = 'UPDATE_EQUIPMENT_VARIABLES_VALUE_ERROR';

// Delete Equipment Variable
export const DELETE_EQUIPMENT_VARIABLES_VALUE_LOADING = 'DELETE_EQUIPMENT_VARIABLES_VALUE_LOADING';
export const DELETE_EQUIPMENT_VARIABLES_VALUE_SUCCESS = 'DELETE_EQUIPMENT_VARIABLES_VALUE_SUCCESS';
export const DELETE_EQUIPMENT_VARIABLES_VALUE_ERROR = 'DELETE_EQUIPMENT_VARIABLES_VALUE_ERROR';

// Equipment Details Permission
export const GET_EQUIPMENT_PERMISSION_BY_UNIT_ID_SUCCESS = 'GET_EQUIPMENT_PERMISSION_BY_UNIT_ID_SUCCESS';
export const GET_EQUIPMENT_PERMISSION_BY_UNIT_ID_ERROR = 'GET_EQUIPMENT_PERMISSION_BY_UNIT_ID_ERROR';

// Update Bulk Equipment Details
export const BULK_EDIT_EQUIPMENT_DETAILS_SAVING = 'BULK_EDIT_EQUIPMENT_DETAILS_SAVING';
export const BULK_EDIT_EQUIPMENT_DETAILS_SUCCESS = 'BULK_EDIT_EQUIPMENT_DETAILS_SUCCESS';

// Get Building Variables Permission
export const GET_BUILDING_VARIABLES_PERMISSION_SUCCESS = 'GET_BUILDING_VARIABLES_PERMISSION_SUCCESS';
export const GET_BUILDING_VARIABLES_PERMISSION_ERROR = 'GET_BUILDING_VARIABLES_PERMISSION_ERROR';

// Get Building Variables Permisison By UnitID
export const GET_BUILDING_VARIABLES_PERMISSION_BY_UNIT_ID_SUCCESS = 'GET_BULIDING_VARIABLES_PERMISSION_BY_UNIT_ID_SUCCESS';
export const GET_BUILDING_VARIABLES_PERMISSION_BY_UNIT_ID_ERROR = 'GET_BULIDING_VARIABLES_PERMISSION_BY_UNIT_ID_ERROR';


// Get Org Permission
export const GET_ORG_PERMISSION_SUCCESS = 'GET_ORG_PERMISSION_SUCCESS';
export const GET_ORG_PERMISSION_ERROR = 'GET_ORG_PERMISSION_ERROR';

// Get Point Permission
export const GET_POINT_PERMISSION_SUCCESS = 'GET_POINT_PERMISSION_SUCCESS';
export const GET_POINT_PERMISSION_ERROR = 'GET_POINT_PERMISSION_ERROR';

// Gt Points Permission By Unit ID
export const GET_POINTS_PERMISSION_BY_UNIT_ID_SUCCESS = 'GET_POINTS_PERMISSION_BY_UNIT_ID_SUCCESS';
export const GET_POINTS_PERMISSION_BY_UNIT_ID_ERROR = 'GET_POINTS_PERMISSION_BY_UNIT_ID_ERROR';

// Get Building Variables List
export const GET_BUILDING_VARIABLES_LIST_LOADING = 'GET_BUILDING_VARIABLES_LIST_LOADING';
export const GET_BUILDING_VARIABLES_LIST_SUCCESS = 'GET_BUILDING_VARIABLES_LIST_SUCCESS';
export const GET_BUILDING_VARIABLES_LIST_ERROR = 'GET_BUILDING_VARIABLES_LIST_ERROR';

// Equipment Notes
export const BEGIN_UPDATE_EQUIPMENT_NOTES_CALL = 'BEGIN_UPDATE_EQUIPMENT_NOTES_CALL';
export const UPDATE_EQUIPMENT_NOTES_SUCCESS = 'UPDATE_EQUIPMENT_NOTES_SUCCESS';
export const UPDATE_EQUIPMENT_NOTES_ERROR = 'UPDATE_EQUIPMENT_NOTES_ERROR';

// Configuration Notes
export const BEGIN_UPDATE_CONFIGURATION_NOTES_CALL = 'BEGIN_UPDATE_CONFIGURATION_NOTES_CALL';
export const UPDATE_CONFIGURATION_NOTES_SUCCESS = 'UPDATE_CONFIGURATION_NOTES_SUCCESS';
export const UPDATE_CONFIGURATION_NOTES_ERROR = 'UPDATE_CONFIGURATION_NOTES_ERROR';

// Create Equipment Variable
export const CREATE_BUILDING_VARIABLES_VALUE_LOADING = 'CREATE_BUILDING_VARIABLES_VALUE_LOADING';
export const CREATE_BUILDING_VARIABLES_VALUE_SUCCESS = 'CREATE_BUILDING_VARIABLES_VALUE_SUCCESS';
export const CREATE_BUILDING_VARIABLES_VALUE_ERROR = 'CREATE_BUILDING_VARIABLES_VALUE_ERROR';

// Update Equipment Variable
export const UPDATE_BUILDING_VARIABLES_VALUE_LOADING = 'UPDATE_BUILDING_VARIABLES_VALUE_LOADING';
export const UPDATE_BUILDING_VARIABLES_VALUE_SUCCESS = 'UPDATE_BUILDING_VARIABLES_VALUE_SUCCESS';
export const UPDATE_BUILDING_VARIABLES_VALUE_ERROR = 'UPDATE_BUILDING_VARIABLES_VALUE_ERROR';

// Delete Equipment Variable
export const DELETE_BUILDING_VARIABLES_VALUE_LOADING = 'DELETE_BUILDING_VARIABLES_VALUE_LOADING';
export const DELETE_BUILDING_VARIABLES_VALUE_SUCCESS = 'DELETE_BUILDING_VARIABLES_VALUE_SUCCESS';
export const DELETE_BUILDING_VARIABLES_VALUE_ERROR = 'DELETE_BUILDING_VARIABLES_VALUE_ERROR';

// Update Equipment Profile
export const BEGIN_UPDATE_EQUIPMENT_PROFILE_CALL = 'BEGIN_UPDATE_EQUIPMENT_PROFILE_CALL';
export const UPDATE_EQUIPMENT_PROFILE_SUCCESS = 'UPDATE_EQUIPMENT_PROFILE_SUCCESS';
export const UPDATE_EQUIPMENT_PROFILE_ERROR = 'UPDATE_EQUIPMENT_PROFILE_ERROR';

// Update Point
export const UPDATE_POINT_LOADING = 'UPDATE_POINT_LOADING';
export const UPDATE_POINT_SUCCESS = 'UPDATE_POINT_SUCCESS';
export const UPDATE_POINT_ERROR = 'UPDATE_POINT_ERROR';

// Get Point Type View
export const GET_POINT_TYPE_VIEW_LOADING = 'GET_POINT_TYPE_VIEW_LOADING';
export const GET_POINT_TYPE_VIEW_SUCCESS = 'GET_POINT_TYPE_VIEW_SUCCESS';
export const GET_POINT_TYPE_VIEW_ERROR = 'GET_POINT_TYPE_VIEW_ERROR';

// Map
export const GET_MAP_TOKEN_CLIENT_ID_SUCCESS = 'GET_MAP_TOKEN_CLIENT_ID_SUCCESS';
export const CLEAR_MAP_TOKEN_CLIENT_ID = 'CLEAR_MAP_TOKEN_CLIENT_ID';

// Assign Dashboard Templates
export const GET_AUTHORIZED_USERS = 'GET_AUTHORIZED_USERS';
export const GET_AUTHORIZED_USERS_SUCCESS = 'GET_AUTHORIZED_USERS_SUCCESS';

// Org Dashboard Templates
export const BEGIN_USER_ORG_TEMPLATES_CALL = 'BEGIN_USER_ORG_TEMPLATES_CALL';
export const USER_ORG_TEMPLATES_CALL_ERROR = 'USER_ORG_TEMPLATES_CALL_ERROR';
export const GET_USER_ORG_TEMPLATES_SUCCESS = 'GET_USER_ORG_TEMPLATES_SUCCESS';
export const GET_USER_ORG_TEMPLATES_ERROR = 'GET_USER_ORG_TEMPLATES_ERROR';

export const GET_ORG_TEMPLATES_LIST_SUCCESS = 'GET_ORG_TEMPLATES_LIST_SUCCESS';
export const GET_ORG_TEMPLATES_LIST_ERROR = 'GET_ORG_TEMPLATES_LIST_ERROR';

export const CREATE_ORG_TEMPLATE_SUCCESS = 'CREATE_ORG_TEMPLATE_SUCCESS';
export const CREATE_ORG_TEMPLATE_ERROR = 'CREATE_ORG_TEMPLATE_ERROR';

export const GET_ORG_TEMPLATES_ORGANIZATIONS_SUCCESS = 'GET_ORG_TEMPLATES_ORGANIZATIONS_SUCCESS';
export const GET_ORG_TEMPLATES_ORGANIZATIONS_ERROR = 'GET_ORG_TEMPLATES_ORGANIZATIONS_ERROR';

export const GET_ORG_TEMPLATES_PERMISSION_SUCCESS = 'GET_ORG_TEMPLATES_PERMISSION_SUCCESS';
export const GET_ORG_TEMPLATES_PERMISSION_ERROR = 'GET_ORG_TEMPLATES_PERMISSION_ERROR';

export const GET_USER_ORG_TEMPLATES_PERMISSION_SUCCESS = 'GET_USER_ORG_TEMPLATES_PERMISSION_SUCCESS';

export const GET_ORG_TEMPLATE_ASSIGNED_USERS_SUCCESS = 'GET_ORG_TEMPLATE_ASSIGNED_USERS_SUCCESS';
export const GET_ORG_TEMPLATE_ASSIGNED_USERS_ERROR = 'GET_ORG_TEMPLATE_ASSIGNED_USERS_ERROR';
export const CLEAR_ORG_TEMPLATE_ASSIGNED_USERS = 'CLEAR_ORG_TEMPLATE_ASSIGNED_USERS';

export const BULK_DELETE_ORG_TEMPLATES_SUCCESS = 'BULK_DELETE_ORG_TEMPLATES_SUCCESS';
export const BULK_DELETE_ORG_TEMPLATES_ERROR = 'BULK_DELETE_ORG_TEMPLATES_ERROR';

export const BULK_DELETE_GLOBAL_TEMPLATES_SUCCESS = 'BULK_DELETE_GLOBAL_TEMPLATES_SUCCESS';
export const BULK_DELETE_GLOBAL_TEMPLATES_ERROR = 'BULK_DELETE_GLOBAL_TEMPLATES_ERROR';

export const ASSIGN_UNASSIGN_ORG_TEMPLATE_TO_USERS_SUCCESS = 'ASSIGN_UNASSIGN_ORG_TEMPLATE_TO_USERS_SUCCESS';
export const ASSIGN_UNASSIGN_ORG_TEMPLATE_TO_USERS_ERROR = 'ASSIGN_UNASSIGN_ORG_TEMPLATE_TO_USERS_ERROR';

export const GET_TEMPLATE_ASSIGNED_USERS_SUCCESS = 'GET_TEMPLATE_ASSIGNED_USERS_SUCCESS';
export const GET_TEMPLATE_ASSIGNED_USERS_ERROR = 'GET_TEMPLATE_ASSIGNED_USERS_ERROR';
export const CLEAR_TEMPLATE_ASSIGNED_USERS = 'CLEAR_TEMPLATE_ASSIGNED_USERS';

export const ASSIGN_UNASSIGN_TEMPLATE_TO_USERS_SUCCESS = 'ASSIGN_UNASSIGN_TEMPLATE_TO_USERS_SUCCESS';
export const ASSIGN_UNASSIGN_TEMPLATE_TO_USERS_ERROR = 'ASSIGN_UNASSIGN_TEMPLATE_TO_USERS_ERROR';

export const COPY_ORG_TEMPLATES_TO_ORGS_SUCCESS = 'COPY_ORG_TEMPLATES_TO_ORGS_SUCCESS';
export const COPY_ORG_TEMPLATES_TO_ORGS_ERROR = 'COPY_ORG_TEMPLATES_TO_ORGS_ERROR';

export const GET_ORG_TEMPLATES_ORGANIZATIONS_WITH_CREATE_PERMISSION_SUCCESS = 'GET_ORG_TEMPLATES_ORGANIZATIONS_WITH_CREATE_PERMISSION_SUCCESS';
export const GET_ORG_TEMPLATES_ORGANIZATIONS_WITH_CREATE_PERMISSION_ERROR = 'GET_ORG_TEMPLATES_ORGANIZATIONS_WITH_CREATE_PERMISSION_ERROR';

// Drilldown
export const DRILL_DOWN = 'DRILL_DOWN';
export const SET_DRILL_DOWN_LOADING = 'SET_DRILL_DOWN_LOADING';
export const CLEAR_DRILL_DOWN = 'CLEAR_DRILL_DOWN';

// Home Module Actions
export const SET_HOME_MODULE_LOADING = 'SET_HOME_MODULE_LOADING';
export const GET_HOME_MODULE_SUCCESS = 'GET_HOME_MODULE_SUCCESS';
export const CLEAR_HOME_MODULE = 'CLEAR_HOME_MODULE';
export const SET_HOME_MODULE_CONFIG_SAVING = 'SET_HOME_MODULE_CONFIG_SAVING';
export const UPDATE_HOME_MODULE_CONFIG_SUCCESS = 'UPDATE_HOME_MODULE_CONFIG_SUCCESS';
export const RESET_HOME_MODULE_JSON_FILTERS = 'RESET_HOME_MODULE_JSON_FILTERS';

//Deployment Groups
export const GET_DEPLOYMENT_GROUPS_LOADING = 'GET_DEPLOYMENT_GROUPS_LOADING';
export const GET_DEPLOYMENT_GROUPS_SUCCESS = 'GET_DEPLOYMENT_GROUPS_SUCCESS';
export const GET_DEPLOYMENT_GROUPS_ERROR = 'GET_DEPLOYMENT_GROUPS_ERROR';
export const CREATE_DEPLOYMENT_GROUPS_LOADING = 'CREATE_DEPLOYMENT_GROUPS_LOADING';
export const CREATE_DEPLOYMENT_GROUPS_SUCCESS = 'CREATE_DEPLOYMENT_GROUPS_SUCCESS';
export const CREATE_DEPLOYMENT_GROUPS_ERROR = 'CREATE_DEPLOYMENT_GROUPS_ERROR';
export const UPDATE_DEPLOYMENT_GROUPS_LOADING = 'UPDATE_DEPLOYMENT_GROUPS_LOADING';
export const UPDATE_DEPLOYMENT_GROUPS_SUCCESS = 'UPDATE_DEPLOYMENT_GROUPS_SUCCESS';
export const UPDATE_DEPLOYMENT_GROUPS_ERROR = 'UPDATE_DEPLOYMENT_GROUPS_ERROR';
export const DELETE_DEPLOYMENT_GROUPS_LOADING = 'DELETE_DEPLOYMENT_GROUPS_LOADING';
export const DELETE_DEPLOYMENT_GROUPS_SUCCESS = 'DELETE_DEPLOYMENT_GROUPS_SUCCESS';
export const DELETE_DEPLOYMENT_GROUPS_ERROR = 'DELETE_DEPLOYMENT_GROUPS_ERROR';

// Deployment Details Module Actions
export const RESET_DEPLOYMENT_DETAILS_MODULE_JSON_FILTERS = 'RESET_DEPLOYMENT_DETAILS_MODULE_JSON_FILTERS';

// Deployment Details Equipment Tile Module Actions
export const GET_DEPLOYMENT_DETAILS_MODULE_EQUIPMENT_TILE_LIST_SUCCESS = 'GET_DEPLOYMENT_DETAILS_MODULE_EQUIPMENT_TILE_LIST_SUCCESS';
export const CLEAR_DEPLOYMENT_DETAILS_MODULE_EQUIPMENT_TILE_LIST = 'CLEAR_DEPLOYMENT_DETAILS_MODULE_EQUIPMENT_TILE_LIST';
export const UPDATE_DEPLOYMENT_DETAILS_MODULE_EQUIPMENT_TILE_CONFIG_COLUMNS = 'UPDATE_DEPLOYMENT_DETAILS_MODULE_EQUIPMENT_TILE_CONFIG_COLUMNS';
export const UPDATE_DEPLOYMENT_DETAILS_MODULE_EQUIPMENT_TILE_GRID_CONFIG = 'UPDATE_DEPLOYMENT_DETAILS_MODULE_EQUIPMENT_TILE_GRID_CONFIG';

// Building Module Actions
export const SET_BUILDING_MODULE_LOADING = 'SET_BUILDING_MODULE_LOADING';
export const GET_BUILDING_MODULE_SUCCESS = 'GET_BUILDING_MODULE_SUCCESS';

export const CLEAR_BUILDING_MODULE_LIST = 'CLEAR_BUILDING_MODULE_LIST';

export const UPDATE_BUILDING_MODULE_GRID_CONFIG = 'UPDATE_BUILDING_MODULE_GRID_CONFIG';
export const UPDATE_BUILDING_MODULE_CONFIG_COLUMNS = 'UPDATE_BUILDING_MODULE_CONFIG_COLUMNS';

export const SET_BUILDING_MODULE_CONFIG_SAVING = 'SET_BUILDING_MODULE_CONFIG_SAVING';

export const UPDATE_BUILDING_MODULE_CONFIG_SUCCESS = 'UPDATE_BUILDING_MODULE_CONFIG_SUCCESS';

export const RESET_BUILDING_MODULE_JSON_FILTERS = 'RESET_BUILDING_MODULE_JSON_FILTERS';

export const SET_BUILDING_SAVING = 'SET_BUILDING_SAVING';
export const UPDATE_BUILDING_SUCCESS = 'UPDATE_BUILDING_SUCCESS';

// Lookups
export const GET_COUNTRY_LOOKUP_SUCCESS = 'GET_COUNTRY_LOOKUP_SUCCESS';
export const GET_COUNTRY_LOOKUP_ERROR = 'GET_COUNTRY_LOOKUP_ERROR';

export const GET_COUNTRY_STATE_LOOKUP_SUCCESS = 'GET_COUNTRY_STATE_LOOKUP_SUCCESS';
export const GET_COUNTRY_STATE_LOOKUP_ERROR = 'GET_COUNTRY_STATE_LOOKUP_ERROR';
export const CLEAR_COUNTRY_STATE_LOOKUP = 'CLEAR_COUNTRY_STATE_LOOKUP';

export const GET_TIMEZONE_LOOKUP_SUCCESS = 'GET_TIMEZONE_LOOKUP_SUCCESS';
export const GET_TIMEZONE_LOOKUP_ERROR = 'GET_TIMEZONE_LOOKUP_ERROR';

export const GET_CULTURE_LOOKUP_SUCCESS = 'GET_CULTURE_LOOKUP_SUCCESS';
export const GET_CULTURE_LOOKUP_ERROR = 'GET_CULTURE_LOOKUP_ERROR';

// Equipment Module Actions
export const SET_EQUIPMENT_MODULE_LOADING = 'SET_EQUIPMENT_MODULE_LOADING';
export const GET_EQUIPMENT_MODULE_SUCCESS = 'GET_EQUIPMENT_MODULE_SUCCESS';

export const CLEAR_EQUIPMENT_MODULE_LIST = 'CLEAR_EQUIPMENT_MODULE_LIST';

export const UPDATE_EQUIPMENT_MODULE_GRID_CONFIG = 'UPDATE_EQUIPMENT_MODULE_GRID_CONFIG';
export const UPDATE_EQUIPMENT_MODULE_CONFIG_COLUMNS = 'UPDATE_EQUIPMENT_MODULE_CONFIG_COLUMNS';

export const SET_EQUIPMENT_MODULE_CONFIG_SAVING = 'SET_EQUIPMENT_MODULE_CONFIG_SAVING';

export const UPDATE_EQUIPMENT_MODULE_CONFIG_SUCCESS = 'UPDATE_EQUIPMENT_MODULE_CONFIG_SUCCESS';

export const RESET_EQUIPMENT_MODULE_JSON_FILTERS = 'RESET_EQUIPMENT_MODULE_JSON_FILTERS';

export const SET_EQUIPMENT_SAVING = 'SET_EQUIPMENT_SAVING';
export const UPDATE_EQUIPMENT_SUCCESS = 'UPDATE_EQUIPMENT_SUCCESS';

// Point Module Actions
export const SET_POINT_MODULE_LOADING = 'SET_POINT_MODULE_LOADING';
export const GET_POINT_MODULE_SUCCESS = 'GET_POINT_MODULE_SUCCESS';

export const CLEAR_POINT_MODULE_LIST = 'CLEAR_POINT_MODULE_LIST';

export const UPDATE_POINT_MODULE_GRID_CONFIG = 'UPDATE_POINT_MODULE_GRID_CONFIG';
export const UPDATE_POINT_MODULE_CONFIG_COLUMNS = 'UPDATE_POINT_MODULE_CONFIG_COLUMNS';

export const SET_POINT_MODULE_CONFIG_SAVING = 'SET_POINT_MODULE_CONFIG_SAVING';

export const UPDATE_POINT_MODULE_CONFIG_SUCCESS = 'UPDATE_POINT_MODULE_CONFIG_SUCCESS';

export const RESET_POINT_MODULE_JSON_FILTERS = 'RESET_POINT_MODULE_JSON_FILTERS';

export const SET_POINT_ADMIN_SAVING = 'SET_POINT_ADMIN_SAVING';
export const UPDATE_POINT_ADMIN_SUCCESS = 'UPDATE_POINT_ADMIN_SUCCESS';
