const all = [
  'Cancelled',
  'Completed',
  'In Process',
  'On Hold',
  'Open',
];

const ongoing = [
  'In Process',
  'Open',
];

const completed = [
  'Completed',
];

const onHold = [
  'On Hold',
];


export default {
  all,
  ongoing,
  completed,
  onHold,
};
