import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import IconButton from '../common/IconButton';
import EquipmentDetails from '../equipment/equipmentModal/EquipmentDetails';
import EquipmentProfile from '../diagnostics/diagnosticModal/EquipmentProfile';
import { updateModal } from '../../actions/modalActions';
import { getBuildingSettings, clearBuildingSettings } from '../../actions/buildingSettingsActions';
import { useBuildingByIdPermissions } from '../buildings/buildingModal/hooks';

const EquipmentModal = ({ dataItem, culture, cancelCallback }) => {
  const dispatch = useDispatch();
  const { data: buildingByIdUpdatePermissions, loading } = useBuildingByIdPermissions(dataItem.bid);

  const canEditBuilding = buildingByIdUpdatePermissions.u === true;

  useEffect(() => {
    if (dataItem.bid) {
      dispatch(getBuildingSettings([dataItem.bid]));
    }
    return () => {
      dispatch(clearBuildingSettings());
    };
  }, [dataItem]);

  const showDetailsModal = (details) => {
    dispatch(
      updateModal(
        'EQUIPMENT_MODAL',
        {
          culture,
          modalContent: 'full scrollable',
          cancelCallback,
          dataItem: details,
        },
      ),
    );
  };

  const showEditModal = () => {
    dispatch(
      updateModal(
        'EDIT_EQUIPMENT',
        {
          culture,
          modalContent: 'full scrollable',
          cancelCallback: () => showDetailsModal(dataItem),
          saveCallback: (response) => showDetailsModal(response),
          dataItem,
        },
      ),
    );
  };

  return (
    <div className="box modal-box">
      <div className="modal-header d-flex justify-content-between align-items-center mb-4">
        <div className="modal-header">
          <h1 className="title mb-0">
            {dataItem.equipmentName}
          </h1>
        </div>
        <div>
          <IconButton
            title="Edit Building"
            iconName="flaticon-edit"
            className={classNames('button' && { 'is-loading': loading })}
            disabled={!canEditBuilding}
            onClick={showEditModal}
          />
        </div>
      </div>

      <div className="box modal-main equipment-report-modal-container">
        <EquipmentDetails details={dataItem} culture={culture}  />
        <EquipmentProfile
          readOnly
          showEquipmentInfo={false}
          unitid={dataItem.unitId}
          bid={dataItem.bid}
          eid={dataItem.eid}
          equipmentNotes={dataItem.equipmentNotes}
          configurationNotes={dataItem.configurationNotes}
        />
      </div>

      <div className="modal-footer is-relative d-flex align-items-center justify-content-center">
        <div>
          <button
            className='button is-info is-outlined is-medium mr-2'
            onClick={cancelCallback}
          >
            Close
          </button>
        </div>
      </div>

    </div>
  );
};

EquipmentModal.propTypes = {
  cancelCallback: PropTypes.func.isRequired,
  dataItem: PropTypes.object.isRequired,
  culture: PropTypes.string.isRequired,
};

export default EquipmentModal;
