import React from 'react';
import NotesCustomCell from '../diagnostics/NotesCustomCell';

const cellRenderers = {  notes: (cellprops) => {
  const { field, dataItem, options = {} } = cellprops;

  if (options.renderType === 'csv' || options.renderType === 'excel') {
    return dataItem[field];
  }

  return (<NotesCustomCell cellprops={cellprops} onClick={() => options.onRowClick({ dataItem }) } />);
}};

export default cellRenderers;
