import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import initialState from '../../reducers/initialState';
import diagnosticsPreviewCellRenderers from './diagnosticsPreviewCellRenderers';

const intervalOptions = ['Daily', 'Weekly', 'Monthly'];

export function getDiagnosticsPreviewFiltersFromQueryString(searchObject) {
  const newJsonFilters = [];
  const defaultJsonFilters = initialState.diagnosticsPreviewModule.config.jsonFilters;
  if (searchObject.unitid) {
    const unitids = Array.isArray(searchObject.unitid) ? searchObject.unitid : [searchObject.unitid];
    newJsonFilters.push({
      key: 'client',
      value: unitids.map(Number),
    });
  }
  if (searchObject.bid) {
    const bids = Array.isArray(searchObject.bid) ? searchObject.bid : [searchObject.bid];
    newJsonFilters.push({
      key: 'building',
      value: bids.map(Number),
    });
  }
  if (searchObject.ecid) {
    const ecids = Array.isArray(searchObject.ecid) ? searchObject.ecid : [searchObject.ecid];
    newJsonFilters.push({
      key: 'equipmentClass',
      value: ecids.map(Number),
    });
  }
  if (searchObject.etid) {
    const etids = Array.isArray(searchObject.etid) ? searchObject.etid : [searchObject.etid];
    newJsonFilters.push({
      key: 'equipmentType',
      value: etids.map(Number),
    });
  }
  if (searchObject.eid) {
    const eids = Array.isArray(searchObject.eid) ? searchObject.eid : [searchObject.eid];
    newJsonFilters.push({
      key: 'equipment',
      value: eids.map(Number),
    });
  }
  if (searchObject.aid) {
    const aids = Array.isArray(searchObject.aid) ? searchObject.aid : [searchObject.aid];
    newJsonFilters.push({
      key: 'analysis',
      value: aids.map(Number),
    });
  }
  if (searchObject.rng) {
    newJsonFilters.push({
      key: 'diagnosticAnalysisInterval',
      value: intervalOptions.find((option) => option.toLowerCase() === searchObject.rng.toLowerCase()),
    });
  } else {
    newJsonFilters.push(defaultJsonFilters.find(({ key }) => key === 'diagnosticAnalysisInterval'));
  }
  if (searchObject.sd || searchObject.ed) {
    const start = searchObject.sd
      ? searchObject.sd
      : searchObject.ed;
    const end = searchObject.ed
      ? searchObject.ed
      : searchObject.sd;
    newJsonFilters.push({
      key: 'diagnosticDate',
      value: {
        value: 'custom_range',
        range: { start, end },
      },
    });
  } else {
    newJsonFilters.push({
      'key': 'diagnosticDate',
      'value': {
        'value': 'last_7_days',
        'range': {},
      },
    });
  }
  return newJsonFilters;
}

export function createCellRenderer(column, opts) {
  const renderers = {
    ...diagnosticsPreviewCellRenderers,
  };

  const cell = renderers[column.render];
  const options = { ...opts, ...column.renderOptions };
  return (innerProps) => typeof cell === 'function' ? cell({ ...innerProps, options }) : <GridCell {...innerProps} />;
}

export function createGridDetailDataState(dataState, data = []) {
  const list = data;
  const result = process(list, dataState);
  return {
    dataState,
    result,
  };
}
