import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { getAppliedFiltersWithDefault } from '../../../selectors/appliedDashboardFilterWithDefaultSelector';
import { calculatingFiltersSelector } from '../../../selectors/calculatingFilters';
import { useSettingsSelector } from '../../../selectors/useSettings';
import { getDatasetQueryStringParams } from '../../widgets/widgetUtils';
import { mergeWidgetDashboardFilters } from '../../../utils/widgetFilterUtils';
import { getErrorMessage } from '../../../api/apiErrorHandler';
import { getWidgetData } from '../../../actions/widgetActions';
import { mapDataToSeries } from '../../../utils/widgetDataMapper';
import { CultureContext } from '../../intl';
import { filterFields } from '../../../enums/filters';

const fixedProps = {
  'widgetDrilldown': null,
  'widgetFilters': [],
  'widgetFiltersWithDefault': [],
};

const {
  widgetDrilldown,
  widgetFiltersWithDefault,
} = fixedProps;

const useTaskGroup = (settings) => {
  const dispatch = useDispatch();
  const buildings = useSelector((state) => state.buildings);
  const taskStatuses = useSelector((state) => state.taskStatuses);
  const userSettings = useSelector((state) => useSettingsSelector(state.user));
  const currentFilterField = useSelector((state) => state.appliedFilters.currentFilterField);
  const isCalculatingFilters = useSelector((state) => calculatingFiltersSelector(state.calculatingFilters));
  const dashboardFiltersWithDefault = useSelector((state) => getAppliedFiltersWithDefault(state, settings));

  const {
    id,
    kql,
    filterOverrides,
    queryString: widgetQueryString,
    config,
    datasetIDs,
    crossFilter,
  } = settings;

  const { culture } = useContext(CultureContext);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [datasetError, setDatasetError] = useState(null);
  const [computedConfig, setComputedConfig] = useState({});
  const [datasetResult, setDatasetResult] = useState([]);
  const queryParamsRef = useRef({});

  const mergedFilters = useMemo(
    () => {
      const result = mergeWidgetDashboardFilters(
        dashboardFiltersWithDefault,
        widgetFiltersWithDefault,
        crossFilter.current,
        widgetDrilldown,
        filterOverrides,
      );
      const dateFilter = result.find((each) => each.key === 'taskModifiedDate') ?? [];
      return result
        .filter((each) => ['building'].includes(each.key))
        .concat(dateFilter);
    },
    [dashboardFiltersWithDefault],
  );

  const fetchDataset = () => {
    const availableListValues = { taskStatuses };
    const { newQueryString, newQueryParams } = getDatasetQueryStringParams({
      kql,
      widgetQueryString,
      datasetIDs,
      userSettings,
      mergedFilters,
      availableListValues,
      buildings,
    });
    loadDataset({
      queryString: newQueryString,
      queryParams: newQueryParams,
    });
    setHasLoaded(true);
  };

  const debouncedFetchDataset = useCallback(
    debounce(fetchDataset, 350),
    [mergedFilters, kql, widgetQueryString],
  );

  useEffect(() => {
    if (!hasLoaded && !isCalculatingFilters && currentFilterField === filterFields.homeModule) {
      debouncedFetchDataset();
      return () => {
        debouncedFetchDataset.cancel();
      };
    }
  }, [hasLoaded, isCalculatingFilters, currentFilterField]);

  function loadDataset({ queryString, queryParams }) {
    const queryStringUpdated = `${queryString}&${filterOverrides}`.replace(/DateModified/g, '');
    const widgetDataLookupObject = {
      id,
      datasetIDs,
      queryParams: {},
      queryString: queryStringUpdated,
      dataset: 'taskgroup',
      lookupEndpoint: 'taskgroup',
    };
    setFetching(true);
    dispatch(getWidgetData(widgetDataLookupObject))
      .then((data) => {
        queryParamsRef.current = queryParams;
        if (!data.length) {
          errorHandler('No data found for the filter selections.');
        } else {
          mapDatasetToWidget(data);
        }
      })
      .catch((err) => {
        let errorMessage = 'Fetching dataset failed.';

        if (err.status === 422 && err.json && err.json.Meta && err.json.Meta.AdditionalDetail) {
          errorMessage = getErrorMessage(err);
        }

        errorHandler(errorMessage);
      }).finally(() => {
        setFetching(false);
      });
  }

  function mapDatasetToWidget(response) {
    let newConfig = { ...config, datasetIDs };

    if (newConfig.dataMapper) {
      const mappedSeries = newConfig.dataMapper.map((series) => mapDataToSeries(series, response, newConfig));
      const mappedData = mappedSeries.flat();

      newConfig.series = mappedData;

      const analysisFilter = mergedFilters.find((filter) => filter.key === 'diagnosticAnalysisInterval');
      if (analysisFilter !== undefined && newConfig.analysisInterval && analysisFilter.value !== newConfig.analysisInterval) {
        newConfig.analysisInterval = analysisFilter.value;
      }
      newConfig.culture = culture;
    }

    setComputedConfig(newConfig);
    setDatasetResult(response);
  }

  function errorHandler(errorMessage) {
    setDatasetError(errorMessage);
  }

  return {
    culture,
    fetching: fetching || !hasLoaded,
    datasetError,
    datasetResult,
    isCalculatingFilters,
    config: computedConfig,
    queryParams: queryParamsRef.current,
    isoCurrencySymbol: userSettings.isoCurrencySymbol,
  };
};

export default useTaskGroup;
