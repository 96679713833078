import { filterIds } from './filters';

const allDashboardFilters = [
  {
    'id': filterIds.equipmentClass,
    'display': 'Class',
    'value': 'equipmentClass',
    'type': 'Equipment',
  },
  {
    'id': filterIds.equipmentType,
    'display': 'Type',
    'value': 'equipmentType',
    'type': 'Equipment',
  },
  {
    'id': filterIds.equipment,
    'display': 'Equip',
    'value': 'equipment',
    'type': 'Equipment',
  },
  {
    'id': filterIds.isEquipmentVisible,
    'display': 'Visibility',
    'value': 'isEquipmentVisible',
    'type': 'ThreeState',
  },
  {
    'id': filterIds.isEquipmentActive,
    'display': 'Active',
    'value': 'isEquipmentActive',
    'type': 'ThreeState',
  },
  {
    'id': filterIds.configurationStatusId,
    'display': 'Configuration Status',
    'value': 'configurationStatusId',
    'type': 'ConfigurationStatus',
  },
  {
    'id': filterIds.diagnosticDate,
    'display': 'Dates',
    'value': 'diagnosticDate',
    'type': 'DateRange',
  },
  {
    'id': filterIds.diagnosticAnalysisInterval,
    'display': 'Interval',
    'value': 'diagnosticAnalysisInterval',
    'type': 'DiagnosticAnalysisInterval',
  },
  {
    'id': filterIds.analysis,
    'display': 'Analysis',
    'value': 'analysis',
    'type': 'Analysis',
  },
  {
    'id': filterIds.energy,
    'display': 'Energy',
    'value': 'energy',
    'type': 'Priority',
  },
  {
    'id': filterIds.comfort,
    'display': 'Comfort',
    'value': 'comfort',
    'type': 'Priority',
  },
  {
    'id': filterIds.maintenance,
    'display': 'Maintenance',
    'value': 'maintenance',
    'type': 'Priority',
  },
  {
    'id': filterIds.avoidableCost,
    'display': 'Avoidable Cost',
    'value': 'avoidableCost',
    'type': 'Cost',
  },
  {
    'id': filterIds.taskOpenedDate,
    'display': 'Date Created',
    'value': 'taskOpenedDate',
    'type': 'DateRange',
  },
  {
    'id': filterIds.taskCompletionDate,
    'display': 'Date Completed',
    'value': 'taskCompletionDate',
    'type': 'DateRange',
  },
  {
    'id': filterIds.taskModifiedDate,
    'display': 'Date Modified',
    'value': 'taskModifiedDate',
    'type': 'DateRange',
  },
  {
    'id': filterIds.taskStatus,
    'display': 'Task Status',
    'value': 'taskStatus',
    'type': 'TaskStatus',
  },
  {
    'id': filterIds.taskAssignee,
    'display': 'Task Assignee',
    'value': 'taskAssignee',
    'type': 'TaskAssignee',
  },
  {
    'id': filterIds.taskReporter,
    'display': 'Task Reporter',
    'value': 'taskReporter',
    'type': 'TaskReporter',
  },
  {
    'id': filterIds.annualAvoidableCost,
    'display': 'Avoidable Cost',
    'value': 'annualAvoidableCost',
    'type': 'Cost',
  },
  {
    'id': filterIds.currency,
    'display': 'Currencies',
    'value': 'currency',
    'type': 'Currency',
  },
  {
    'id': filterIds.provider,
    'display': 'Provider',
    'value': 'provider',
    'type': 'Global',
  },
  {
    'id': filterIds.client,
    'display': 'Org',
    'value': 'client',
    'type': 'Global',
  },
  {
    'id': filterIds.deploymentGroup,
    'display': 'Deployments',
    'value': 'deploymentGroup',
    'type': 'Building',
  },
  {
    'id': filterIds.buildingGroup,
    'display': 'Groups',
    'value': 'buildingGroup',
    'type': 'Building',
  },
  {
    'id': filterIds.buildingClass,
    'display': 'Class',
    'value': 'buildingClass',
    'type': 'Building',
  },
  {
    'id': filterIds.buildingType,
    'display': 'Types',
    'value': 'buildingType',
    'type': 'Building',
  },
  {
    'id': filterIds.building,
    'display': 'Buildings',
    'value': 'building',
    'type': 'Building',
  },
  {
    'id': filterIds.rawdatasetDate,
    'display': 'Date Range',
    'value': 'rawdatasetDate',
    'type': 'RawdatasetDate',
  },
  {
    'id': filterIds.aggregationDate,
    'display': 'Date Range',
    'value': 'aggregationDate',
    'type': 'DateRange',
  },
  {
    'id': filterIds.aggregationInterval,
    'display': 'Interval',
    'value': 'aggregationInterval',
    'type': 'AggregationInterval',
  },
  {
    'id': filterIds.pointClass,
    'display': 'Point Class',
    'value': 'pointClass',
    'type': 'Point',
  },
  {
    'id': filterIds.pointType,
    'display': 'Point Type',
    'value': 'pointType',
    'type': 'Point',
  },
  {
    'id': filterIds.point,
    'display': 'Point',
    'value': 'point',
    'type': 'Point',
  },
  {
    'id': filterIds.vPoint,
    'display': 'Calculated Point',
    'value': 'vPoint',
    'type': 'Point',
  },
  {
    'id': filterIds.dataSource,
    'display': 'Data Source',
    'value': 'dataSource',
    'type': 'DataSource',
  },
  {
    'id': filterIds.isBuildingVisible,
    'display': 'Visibility',
    'value': 'isBuildingVisible',
    'type': 'ThreeState',
  },
  {
    'id': filterIds.isBuildingActive,
    'display': 'Active',
    'value': 'isBuildingActive',
    'type': 'ThreeState',
  },
  {
    'id': filterIds.isPointActive,
    'display': 'Active',
    'value': 'isPointActive',
    'type': 'ThreeState',
  },
];

export default allDashboardFilters;
