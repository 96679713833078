import React from 'react';
import { useSelector } from 'react-redux';
import { GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import BaseGrid from '../../common/Grid/BaseGrid';
import useGridDataState from '../../../hooks/useGridDataState';

const EquipmentDetailsReferenceGrid = () => {
  const loading = useSelector((state) => state.ajaxCallsInProgress.equipmentDataset);
  const data = useSelector((state) => state.equipmentDataset);
  const { dataState, onDataStateChange } = useGridDataState(data, config, true);

  return (
    <BaseGrid
      resizable
      scrollable="none"
      wrapperClassName="equipment-details-reference-grid borderless-grid"
      data={dataState.result}
      {...dataState.dataState}
      onDataStateChange={onDataStateChange}
    >
      {columns.map((column) => (
        <GridColumn
          {...column}
          key={column.field} 
          field={column.field}
          title={column.title}
        />
      ))}
      <GridNoRecords>
        {loading
          ? <i className="bulma-loader-mixin m-auto" aria-hidden="true"></i>
          : 'No records available'
        }
      </GridNoRecords>
    </BaseGrid>
  );
};

const config = {
  sort: [],
};

const columns = [
  {
    field: 'equipmentName',
    title: 'Name',
  },
  {
    field: 'equipmentClassName',
    title: 'Class',
  },
  {
    field: 'equipmentTypeName',
    title: 'Type',
  },
  {
    field: 'configurationStatusName',
    title: 'Configuration Status',
  },
  {
    field: 'isActive',
    title: 'Active',
    className: 'is-uppercase',
  },
  {
    field: 'isVisible',
    title: 'Visible',
    className: 'is-uppercase',
  },
];

export default EquipmentDetailsReferenceGrid;
