import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { addOrUpdateFilter } from '../actionHelpers/commonHelpers';
import modules from '../enums/modules';
import { filterFields } from '../enums/filters';

export default function buildingModuleReducer(state = initialState.buildingModule, action) {
  switch (action.type) {
    case types.ADD_OR_UPDATE_JSON_FILTERS:
      if (action.currentFilterField !== filterFields.buildingModule) return state;
      return {
        ...state,
        config: {
          ...state.config,
          jsonFilters: addOrUpdateFilter(state.config.jsonFilters, action.jsonFilter, action.shouldReplaceAll, action.isPartialUpdate),
        },
      };
    case types.REMOVE_JSON_FILTERS:
      if (action.currentFilterField !== filterFields.buildingModule) return state;
      return {
        ...state,
        config: {
          ...state.config,
          jsonFilters: state.config.jsonFilters.filter((eachFilter) => eachFilter.key !== action.key),
        },
      };

    case types.UPDATE_BUILDING_MODULE_CONFIG_COLUMNS:
      return {
        ...state,
        config: {
          ...state.config,
          jsonLayout: {
            ...state.config.jsonLayout,
            columns: action.payload,
          },
        },
      };

    case types.UPDATE_BUILDING_MODULE_GRID_CONFIG:
      return {
        ...state,
        config: {
          ...state.config,
          jsonLayout: {
            ...state.config.jsonLayout,
            grid: action.payload,
          },
        },
      };

    case types.GET_MODULE_CONFIG_SUCCESS: {
      const moduleConfig = action.payload.find((e) => e.moduleId === modules.userModules.Building);
      if (moduleConfig) {
        return {
          ...state,
          config: {
            jsonFiltersFromAPILoaded: true,
            jsonLayout: moduleConfig.jsonLayout,
            jsonFilters: moduleConfig.jsonFilters,
            jsonFiltersFromAPI: moduleConfig.jsonFilters,
          },
        };
      }
      return state;
    }

    case types.UPDATE_BUILDING_MODULE_CONFIG_SUCCESS: {
      return {
        ...state,
        config: {
          ...state.config,
          jsonFiltersFromAPI: JSON.parse(action.payload.update.jsonFilters),
        },
      };
    }

    case types.RESET_BUILDING_MODULE_JSON_FILTERS: {
      return {
        ...state,
        config: {
          ...state.config,
          jsonFilters: state.config.jsonFiltersFromAPI,
        },
      };
    }

    case types.SET_BUILDING_MODULE_CONFIG_SAVING:
      return {
        ...state,
        configSaving: action.payload,
      };


    // GET BUILDING LIST
    case types.GET_BUILDING_MODULE_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };

    case types.CLEAR_BUILDING_MODULE_LIST:
      return {
        ...state,
        list: [],
      };

    // Building Permissions
    case types.GET_BUILDING_BY_ID_PERMISSION_SUCCESS:
      return {
        ...state,
        buildingByIdPermissions: action.payload,
      };

    case types.CLEAR_BUILDING_BY_ID_PERMISSION:
      return {
        ...state,
        buildingByIdPermissions: {},
      };

      // Update Building
    case types.SET_BUILDING_SAVING:
      return {
        ...state,
        buildingSaving: action.payload,
      };

    case types.UPDATE_BUILDING_SUCCESS:
      return {
        ...state,
        lastUpdated: action.payload,
      };
  
    default:
      return state;
  }
}
