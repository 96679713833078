import React from 'react';
import { Link } from 'react-router-dom';
import PageLoading from '../../../common/PageLoading';
import WidgetRenderer from '../../../widgets/WidgetRenderer';
import useTileManager from '../../hooks/useTileManager';
import { dataPointsConfigSettings, dataPointsConfigSettings2, dataPointsConfigSettings3 } from './config';

const DataPointsTile = () => {
  const { datasetResult, fetching } = useTileManager(dataPointsConfigSettings);
  const { config: config2, datasetResult: datasetResult2, fetching: fetching2 } = useTileManager(dataPointsConfigSettings2);
  const { datasetResult: notReportingData } = useTileManager(dataPointsConfigSettings3);
  const count = datasetResult.length ? datasetResult[0].count : 0;

  if (fetching || fetching2) return <PageLoading label={'Loading'} boxClass={'widgetLoader'} />;

  return (
    <>
      <div className='summary'>
        <i className='icon flaticon-graph' aria-hidden="true" />
        <p className="value">{count}</p>
        <p className="label">data points</p>
      </div>
      <div className="widget-renderer-wrapper">
        <div className="widget-renderer is-relative" style={{ height: 250 }}>
          {/* <span className="floating-percentage">97%</span> */}
          <WidgetRenderer
            wtid={-1}
            config={config2}
            datasetResult={datasetResult2}
            forceUpdateConfig={JSON.stringify(config2)}
            id={dataPointsConfigSettings2.id}
            type={dataPointsConfigSettings2.type}
            crossFilter={dataPointsConfigSettings2.crossFilter}
            datasetIDs={dataPointsConfigSettings2.datasetIDs}
          />
        </div>
        <div className="layout-wrapper">
          {notReportingData.length ? (
            <Link
              className="link row-item"
              to={`/assets/points?pid=${notReportingData.map((e) => e.pid).join(',')}`}
            >
              <span className="item-label">Not Reporting Data</span> <span className="item-value">
                {notReportingData.length}
              </span>
            </Link>
          ) : (
            <div className="row-item">
              <span className="item-label">Not Reporting Data</span> <span className="item-value">
                {notReportingData.length}
              </span>
            </div>
          )}
        </div>
      </div>
     
    </>
  );
};

export default DataPointsTile;
