/**
 *  * Created by Stewart Gordon on 6/11/2018.
 */
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function dashboardFilters(state = initialState.dashboardFilters, action) {
  switch (action.type) {
    case types.SET_DASHBOARD_FILTERS_LOADED: {
      return {
        ...state,
        dashboardFiltersLoaded: action.payload,
      };
    }

    case types.SET_WIDGET_FILTERS_LOADED: {
      return {
        ...state,
        widgetFiltersLoaded: action.payload,
      };
    }

    case types.PROVIDERS_SUCCESS: {
      return {
        ...state,
        providerAll: action.providers,
        provider: action.providers,
        providerLoaded: true,
      };
    }

    case types.UNITS_SUCCESS: {
      return {
        ...state,
        clientAll: action.units,
        client: action.units,
      };
    }

    case types.UNIT_DEPLOYMENT_GROUPS_SUCCESS: {
      return {
        ...state,
        deploymentGroupAll: action.unitDeploymentGroups,
        deploymentGroup: action.unitDeploymentGroups,
        deploymentGroupLoaded: true,
      };
    }

    case types.UNIT_BUILDING_GROUPS_SUCCESS: {
      return {
        ...state,
        buildingGroupAll: action.unitBuildingGroups,
        buildingGroup: action.unitBuildingGroups,
        buildingGroupLoaded: true,
      };
    }

    case types.BUILDING_CLASSES_SUCCESS: {
      return {
        ...state,
        buildingClassAll: action.buildingClasses,
        buildingClass: action.buildingClasses,
        buildingClassLoaded: true,
      };
    }
    
    case types.BUILDING_TYPES_SUCCESS: {
      return {
        ...state,
        buildingTypeAll: action.buildingTypes,
        buildingType: action.buildingTypes,
        buildingTypeLoaded: true,
      };
    }
    
    case types.BUILDINGS_SUCCESS: {
      return {
        ...state,
        buildingAll: action.buildings,
        building: action.buildings,
        buildingLoaded: true,
      };
    }
    
    case types.GET_EQUIPMENT_CLASSES_SUCCESS: {
      return {
        ...state,
        equipmentClassAll: action.list,
        equipmentClass: action.list,
      };
    }

    case types.GET_EQUIPMENT_TYPES_SUCCESS: {
      return {
        ...state,
        equipmentTypeAll: action.list,
        equipmentType: action.list,
      };
    }

    case types.GET_EQUIPMENTS_SUCCESS: {
      return {
        ...state,
        equipmentAll: action.list,
        equipment: action.list,
      };
    }

    case types.GET_POINT_CLASSES_SUCCESS: {
      return {
        ...state,
        pointClassAll: action.list,
        pointClass: action.list,
      };
    }

    case types.GET_POINT_TYPES_SUCCESS: {
      return {
        ...state,
        pointTypeAll: action.list,
        pointType: action.list,
      };
    }

    case types.GET_POINTS_SUCCESS: {
      return {
        ...state,
        pointAll: action.list,
        point: action.list,
      };
    }

    case types.GET_V_POINTS_SUCCESS: {
      return {
        ...state,
        vPointAll: action.list,
        vPoint: action.list,
      };
    }

    case types.FILTER_EQUIPMENT_TYPES: {
      return {
        ...state,
        equipmentType: action.list,
      };
    }

    case types.FILTER_EQUIPMENTS: {
      return {
        ...state,
        equipment: action.list,
      };
    }

    case types.GET_TASK_ASSIGNEES_SUCCESS: {
      return {
        ...state,
        taskAssigneeAll: action.list,
      };
    }

    case types.FILTER_TASK_ASSIGNEES: {
      return {
        ...state,
        taskAssignee: action.list,
      };
    }

    case types.GET_DATA_SOURCES_SUCCESS: {
      return {
        ...state,
        dataSourceAll: action.list,
      };
    }

    case types.FILTER_DATA_SOURCES: {
      return {
        ...state,
        dataSource: action.list,
      };
    }

    case types.GET_TASK_REPORTERS_SUCCESS: {
      return {
        ...state,
        taskReporterAll: action.list,
      };
    }

    case types.FILTER_TASK_REPORTERS: {
      return {
        ...state,
        taskReporter: action.list,
      };
    }

    case types.GET_ANALYSES_SUCCESS: {
      return {
        ...state,
        analysisAll: action.list,
      };
    }

    case types.FILTER_ANALYSES: {
      return {
        ...state,
        analysis: action.list,
      };
    }

    case types.GET_EQUIPMENT_ANALYSES_SUCCESS: {
      return {
        ...state,
        equipmentAnalysisAll: action.list,
      };
    }

    case types.GET_CURRENCIES_SUCCESS: {
      return {
        ...state,
        currencyAll: action.list,
      };
    }

    case types.FILTER_CURRENCIES: {
      return {
        ...state,
        currency: action.list,
      };
    }

    case types.SET_GROUP_DROPDOWN_VALUES: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.SET_GLOBAL_GROUP_DROPDOWN_VALUES: {
      return {
        ...state,
        provider: action.payload.provider,
        client: action.payload.client,
        providerLoaded: true,
        clientLoaded: true,
      };
    }

    case types.SET_BUILDINGS_GROUP_DROPDOWN_VALUES: {
      return {
        ...state,
        buildingGroup: action.payload.buildingGroup,
        buildingClass: action.payload.buildingClass,
        buildingType: action.payload.buildingType,
        building: action.payload.building,
        buildingLoaded: true,
      };
    }
  
    case types.SET_EQUIPMENTS_GROUP_DROPDOWN_VALUES: {
      return {
        ...state,
        equipmentClass: action.payload.equipmentClass,
        equipmentClassAll: action.payload.equipmentClassAll,
        equipmentType: action.payload.equipmentType,
        equipmentTypeAll: action.payload.equipmentTypeAll,
        equipment: action.payload.equipment,
        equipmentAll: action.payload.equipmentAll,
        equipmentLoaded: true,
      };
    }

    case types.INITIALIZE_AVAILABLE_DATA: {
      return {
        ...state,
        availableData: action.available,
      };
    }

    case types.SET_FILTER_OPEN: {
      return {
        ...state,
        filterOpen: action.payload,
      };
    }

    case types.CLEAR_DASHBOARD_FILTERS: {
      return {
        ...state,
        client: [],
        buildingClass: [],
        buildingType: [],
        building: [],
        equipmentClass: [],
        equipmentType: [],
        equipment: [],
        pointClass: [],
        pointType: [],
        point: [],
        taskAssignee: [],
        taskReporter: [],
        currency: [],
        analysis: [],
      };
    }

    default:
      return state;
  }
}
