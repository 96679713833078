import { widgetDatasets } from '../../../../enums/datasets';
import widgetType from '../../../widgets/widgetType';

export const equipmentConfigSettings = {
  'id': 'equipmentConfigSettingsId',
  'kql': `
    EquipmentLiveDataset
    | join kind=leftouter 
    (
        DiagnosticResultsDataset
        | where ResultClassID == 11
        | summarize ResultCount=count() by EquipmentID
        | project-rename EquipMissingInfoResultFound=ResultCount
    )
    on EquipmentID
    | join kind=leftouter 
    (
        DiagnosticResultsDataset
        | where ResultClassID == 6
        | summarize ResultCount=count() by EquipmentID
        | project-rename ConfigIssueResultFound=ResultCount
    )
    on EquipmentID
    | extend EquipMissingInfo=(EquipMissingInfoResultFound > 0)
    | extend ConfigurationIssues=(ConfigIssueResultFound > 0)
    | project EquipmentID, EquipmentName, EquipmentTypeID, EquipmentTypeName, EquipmentClassID, EquipmentClassName, EquipMissingInfo, ConfigurationIssues
  `,
  'filterOverrides': 'DiagnosticStartDate=$d1&DiagnosticEndDate=$d0&DiagnosticAnalysisInterval=Daily',
  'datasetIDs': [widgetDatasets.Equipment_LIVE, widgetDatasets.DiagnosticResultsDataset],
  'type': widgetType.chart,
  'config': {
    'chart': {
      'type': 'pie',
    },
    'exporting': {
      'enabled': false,
    },
    'boost': {
      'enabled': false,
    },
    'plotOptions': {
      'series': {
        'borderRadius': 3,
        'innerSize': '65%',
        'showInLegend': true,
        'dataLabels': {
          'enabled': false,
        },
      },
    },
    'tooltip': {
      'enabled': true,
    },
    'credits': false,
    'title': {
      'text': null,
    },
    'legend': {
      'enabled': true,
      'verticalAlign': 'top',
      'layout': 'vertical',
      'x': 0,
      'y': 0,
    },
    'series': [],
    'dataMapper': [
      {
        'name': {
          'type': 'string',
          'value': 'Equipment Class Count',
        },
        'data': {
          'name': {
            'type': 'field',
            'value': 'equipmentClassName',
          },
          'y': {
            'type': 'field',
            'value': 'equipCount',
          },
        },
      },
    ],
  },
  'crossFilter': {
    'enabled': false,
    'areEqual': false,
    'current': {
      'widgetId': null,
      'filters': [],
    },
    'previous': {
      'widgetId': null,
      'filters': [],
    },
  },
};

export const equipmentOptions = {
  preDataMapper: (response) => {
    const dictionary = {};
    response.forEach((item) => {
      const key = item.equipmentClassName;
      if (dictionary[key]) {
        dictionary[key].equipCount += 1;
      } else {
        dictionary[key] = {
          equipmentClassName: key,
          equipmentClass: item.equipmentClassId,
          equipmentClassId: item.equipmentClassId,
          equipCount: 1,
        };
      }
    });
    return Object.values(dictionary);
  },
};
